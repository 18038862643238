import React, {useEffect} from 'react';
import s from './Accounts.module.css'
import styles from "../../../styles/styles.module.css"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {AccountStatement} from "./AccountStatement/AccountStatement";
import {getTransactionsListThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {AccountTopUp} from "./AccountTopUp/AccountTopUp";
import { AccountOverview } from './AccountOverview/AccountOverview';
import { useLocation } from 'react-router-dom';

const Accounts = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(state => state.userReducer.user.userToken);

  const location = useLocation();
  const { tab } = location.state || {};
  const defaultIndex = tab === 'statement' ? 1 : tab === 'topup' ? 2 : 0;

  useEffect(() => {
    dispatch(getTransactionsListThunk(token))
  }, [dispatch, token]);

  return (
    <div className={s.container}>
      <Tabs defaultIndex={defaultIndex}>
        <TabList className={styles.tabstitle_block}>
          <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Overview</Tab>
          <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Statement</Tab>
          <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Account Top-Up</Tab>
        </TabList>
        <TabPanel>
          <AccountOverview/>
        </TabPanel>
        <TabPanel>
          <AccountStatement/>
        </TabPanel>
        <TabPanel>
          <AccountTopUp/>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Accounts;