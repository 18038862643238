import React, { Dispatch, SetStateAction, useState } from "react";
import s from "./CardsStepThree.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import TopUpModal from "../../../../components/Card/TopUpModal/TopUpModal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import CardTransactions from "../../../../components/Card/CardTransactions/CardTransactions";
import { activatePhysicalCard } from "../../../../store/reducers/ActionCreators";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import Icon from "@ant-design/icons";
import { ReactComponent as Back } from "../../../../assets/images/personalAccount/back.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/images/personalAccount/copy.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/images/personalAccount/view.svg";
import { ReactComponent as NoViewIcon } from "../../../../assets/images/personalAccount/no-view.svg";
import { setDisabled } from "../../../../store/reducers/UserSlice";
import CardSettings from "../../../../components/Card/CardSettings/CardSettings";
import CardsStepOne from "../CardsStepOne/CardsStepOne";

type PropsType = {
    current: number;
    setCurrent: Dispatch<SetStateAction<number>>;
    setSelectedCard: Dispatch<SetStateAction<any>>;
};

const CardsStepThree = (props: PropsType) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [hideContent, setHideContent] = useState(false);
    const [showCVV, setShowCVV] = useState(false);
    const [targetCard, setTargetCard]: any[any] = useState([])

    const token = useAppSelector(shippingCountyState => shippingCountyState.userReducer.user.userToken)
    const baseCardList: any[any] = useAppSelector(state => state.userReducer.cardInfo);
    const userCards = baseCardList.filter((card: any) => card.status !== -1 && card.status !== 9)

    const dispatch = useAppDispatch();

    function maskCardNumber(cardNumber: string | number) {
        cardNumber = cardNumber.toString().replace(/\s/g, "");
        const maskedPart = cardNumber.slice(4, -4).replace(/\d/g, "*");
        return (
            cardNumber.slice(0, 4) +
            " " +
            maskedPart.replace(/(.{4})/g, "$1 ").trim() +
            " " +
            cardNumber.slice(-4)
        );
    }

    const handleShowCVV = () => {
        setShowCVV(!showCVV);
    };
    

    const handleActivatePhysicalCard = () => {
        const dataForTransaction = {
            san: targetCard && targetCard.san,
        };
        dispatch(setDisabled(true))
        dispatch(activatePhysicalCard(token, dataForTransaction))
            .then(response => {
                setShowModal(false)
                setIsSuccessModalOpen(true)
            })
            .catch(error => {
                console.error('Error activating physical card:', error);
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    };

    const copyTextToClipboard = async (cardNumber: any) => {
        try {
            await navigator.clipboard.writeText(cardNumber);
        } catch (err) {
            console.error("Failed to copy:", err);
        }
    };

    const renderCard = (card: any, index: any) => (
        <React.Fragment key={index}>
        <>
            <div className={s.card_wrapper}>
                <div className={s.credit_card}>
                    <div className={s.card_front}>
                        <div className={s.chip}></div>
                        <div className={s.cardNumber}>
                            {card.type === 4
                                ? maskCardNumber(
                                      card.cardNumber
                                  )
                                : card.cardNumber}
                        </div>
                        <div className={s.expireDate}>
                            {card.expireDate}
                        </div>
                        <div className={s.cardHolder}>
                            {card.cardHolder}
                        </div>
                        <div
                            className={s.masterCard}
                        ></div>
                    </div>
                </div>
                <div className={s.cardDetailsWrapper}>
                    <div className={s.detailRow}>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                CARD NUMBER
                                <div
                                    className={s.detail}
                                >
                                    {card.type === 4
                                        ? maskCardNumber(
                                              card.cardNumber
                                          )
                                        : card.cardNumber}
                                    <button
                                        onClick={() => copyTextToClipboard(card.cardNumber)}
                                        className={
                                            s.copyButton
                                        }
                                    >
                                        <CopyIcon fill="#2046A1" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                CVV
                                <div
                                    className={s.detail}
                                >
                                    {showCVV
                                        ? card.cvv
                                        : "***"}
                                    <div
                                        className={
                                            s.view
                                        }
                                        onClick={
                                            handleShowCVV
                                        }
                                    >
                                        {showCVV ? (
                                            <NoViewIcon fill="#2046A1" />
                                        ) : (
                                            <ViewIcon fill="#2046A1" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.detailRow}>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                EXPIRATION DATE
                                <div
                                    className={s.detail}
                                >
                                    {card.expireDate}
                                </div>
                            </div>
                        </div>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                SAN NUMBER
                                <div
                                    className={s.detail}
                                >
                                    {card.san}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.detailRow}>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                CARD STATUS
                                <div
                                    className={`${
                                        s.detail
                                    } ${
                                        card.status ===
                                        3
                                            ? s.redText
                                            : ""
                                    }`}
                                >
                                    {card.statusText}
                                </div>
                            </div>
                        </div>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                CARD BALANCE
                                <div
                                    className={s.detail}
                                >
                                    {card.balance} {card.currency}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.detailRow}>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            />
                        </div>
                        <div className={s.detailBlock}>
                            <div
                                className={
                                    s.detailTitle
                                }
                            >
                                <MyBtn
                                    title={
                                        "+ Add Funds"
                                    }
                                    style={{
                                        maxWidth: 180,
                                    }}
                                    large
                                    onClick={() => {
                                        setIsModalOpen(
                                            true
                                        )
                                        setTargetCard(card);
                                        console.log('selected card is', card.cardNumber)
                                    }
                                    }
                                    type={"submit"}
                                    localDisable={
                                        card.status === 3
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {card.type === 1 ? (
                <div className={s.blockWrapper}>
                    <div className={s.blockTitle}>
                        NEED A PHYSICAL CARD?
                    </div>
                    <div className={s.blockText}>
                        Click on the request physical
                        card button below and we will
                        process the request for you.
                    </div>
                    <MyBtn
                        title={"Request Physical Card"}
                        style={{
                            maxWidth: 245,
                            paddingBottom: 22,
                        }}
                        large
                        onClick={() => {
                            props.setCurrent(3);
                            props.setSelectedCard(card);
                        }}
                        type={"submit"}
                    />
                </div>
            ) : card.type === 3 ? (
                <div className={s.blockWrapper}>
                    <div className={s.blockTitle}>
                        Your card is being delivered!
                    </div>
                    {card.shippingTrackingNo ? (
                        <div className={s.blockText}>
                            Your shipping tracking
                            number is{" "}
                            <div className={s.link}>
                                #
                                {
                                    card.shippingTrackingNo
                                }
                                .
                            </div>
                        </div>
                    ) : (
                        <div
                            className={s.blockText}
                        ></div>
                    )}
                    <MyBtn
                        title={"Activate Physical Card"}
                        style={{
                            maxWidth: 245,
                            paddingBottom: 22,
                        }}
                        large
                        onClick={() => {
                            setShowModal(true);
                            setTargetCard(card);
                        }}
                        type={"submit"}
                    />
                </div>
            ) : (
                <div className={s.blockWrapper}></div>
            )}
            {card.description ? (
                <div className={s.blockText}>
                    Note:{card.description}
                </div>
            ) : (
                ""
            )}
            {index !== userCards.length - 1 && (
                <>
                    <div className={s.hr} />
                    <br />
                </>
            )}
        </>
        </React.Fragment>
    );

    return (
        <>
            <CustomModal
                type={"confirm"}
                setIsModalOpen={setShowModal}
                isModalOpen={showModal}
            >
                <div className={s.modalContent}>
                    <h3 className={s.modalTitle}>Are you sure?</h3>
                    <h4 className={s.modalSubtitle}>
                        Activate your Physical Card only after receiving it, and we will send you card PIN code via e-mail.
                    </h4>
                    <div className={s.buttonsWrapper}>
                        <div className={s.buttonBlock}>
                            <TransparentButton
                                buttonType={"confirm"}
                                medium
                                title={"Cancel"}
                                icon={<Icon component={() => <Back />} rev={undefined} />}
                                onClick={() => setShowModal(false)}
                            />
                        </div>
                        <div className={s.buttonBlock}>
                            <MyBtn
                                buttonType={"confirm"}
                                medium
                                onClick={handleActivatePhysicalCard}
                                title={"Confirm"}
                            />
                        </div>
                    </div>
                </div>
            </CustomModal>

            <CustomModal
                setIsModalOpen={setIsSuccessModalOpen}
                isModalOpen={isSuccessModalOpen}
            >
                <div>
                    <div className={s.modalTitle}>Success!</div>
                    <h4 className={s.modalSubtitle}> Your card has been activated.</h4>
                    <div className={s.buttonsWrapper}>
                        <div className={s.buttonBlock}>
                            <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)} />
                        </div>
                    </div>
                </div>
            </CustomModal>

            <Tabs>
                <TabList className={s.tabstitle_block}>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Card</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Transactions</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Card Settings</Tab>
                </TabList>
                <TabPanel>
                    {(!hideContent && userCards.length >= 1) && (
                        <div className={s.pageWrapper}>
                            <TopUpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedCard={targetCard} />
                            {userCards.map(renderCard)}
                            {userCards.length === 1 && (
                                <CardsStepOne
                                    setCurrent={props.setCurrent}
                                    current={props.current}
                                    secondCard
                                    setHideContent={setHideContent}
                                />
                            )}
                        </div>
                    )}
                    {((hideContent && userCards.length >= 1) || (!hideContent && userCards.length === 0)) && (
                        <CardsStepOne
                            setCurrent={props.setCurrent}
                            current={props.current}
                            secondCard={userCards.length === 1}
                            setHideContent={setHideContent}
                            hideContent={hideContent}
                        />
                    )}
                </TabPanel>
                <TabPanel>
                    <CardTransactions />
                </TabPanel>
                <TabPanel>
                    <CardSettings />
                </TabPanel>
            </Tabs>
        </>
    );
};

export default CardsStepThree;
