import React, { Dispatch, SetStateAction } from "react";
import CustomCheckbox from "../../ui/customCheckbox/CustomCheckbox";
import SelectWithSearch from "../../ui/SelectWithSearch/SelectWithSearch";
import {
    azaBusinessCountries,
    countries,
    languages,
    azaCountries,
} from "../../../constants/StaticData";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import { Popover } from "antd";
import MyInput from "../../ui/MyInput/MyInput";
import { FormikProps } from "formik";
import info from "../../../assets/images/personalAccount/information.svg";
import classnames from "classnames";
import EftForm from "../../BankTransferForm/EFTForm";
import BillPaymentForm from "../../BankTransferForm/BillPaymentForm";
import PanAfricaIndividual from "../../BankTransferForm/PanAfricaIndividual";
import GbpBankBusiness from "../../BankTransferForm/PanAfricaBusiness/GbpBank";
import EurBankBusiness from "../../BankTransferForm/PanAfricaBusiness/EurBank";
import XafBankBusiness from "../../BankTransferForm/PanAfricaBusiness/XafBank";
import XofBankBusiness from "../../BankTransferForm/PanAfricaBusiness/XofBank";
import GhsBankBusiness from "../../BankTransferForm/PanAfricaBusiness/GhsBank";
import UsdBankBusiness from "../../BankTransferForm/PanAfricaBusiness/UsdBank";
import KesBankBusiness from "../../BankTransferForm/PanAfricaBusiness/KesBank";
import NigeriaUsdBankBusiness from "../../BankTransferForm/PanAfricaBusiness/NigeriaUsdBank";
import ZarBankBusiness from "../../BankTransferForm/PanAfricaBusiness/ZarBank";
import EgpBankBusiness from "../../BankTransferForm/PanAfricaBusiness/EgpBank";

type PropsType = {
    s: any;
    values: any;
    setSelectedOptionRecipientType: Dispatch<SetStateAction<any>>;
    setFieldValue: FormikProps<any>["setFieldValue"];
    selectedOptionRecipientType: any;
    errors: any;
    touched: any;
    getItemsPaymentType: (field: string) => string[];
    getBusinessPaymentType: (field: string) => string[];
    setPaymentTypeByRegion?: Dispatch<SetStateAction<any>>;
    handleChange: FormikProps<any>["handleChange"];
    setCode?: Dispatch<SetStateAction<any>>;
};

const FormBody = (props: PropsType) => {
    const {
        s,
        values,
        setSelectedOptionRecipientType,
        setFieldValue,
        selectedOptionRecipientType,
        errors,
        touched,
        getItemsPaymentType,
        getBusinessPaymentType,
        setPaymentTypeByRegion,
        handleChange,
        // eslint-disable-next-line
        setCode,
    } = props;

    return (
        <>
            {values.paymentRegions === "PAN AFRICA" && (
                <div className={s.row}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>RECIPIENT’S TYPE</div>
                        <div className={s.flex_label}>
                            <label className={s.flex_label}>
                                <CustomCheckbox
                                    handleChange={() => {
                                        setSelectedOptionRecipientType(
                                            "INDIVIDUAL"
                                        );
                                        setFieldValue(
                                            "paymentTypeByRegionForm",
                                            ""
                                        );
                                        setFieldValue(
                                            "recipientType",
                                            "INDIVIDUAL"
                                        );
                                    }}
                                    id="INDIVIDUAL"
                                    name="INDIVIDUAL"
                                    isChecked={
                                        selectedOptionRecipientType ===
                                        "INDIVIDUAL"
                                    }
                                />
                                <span
                                    className={
                                        selectedOptionRecipientType ===
                                        "INDIVIDUAL"
                                            ? s.type_of_payment_active
                                            : s.type_of_payment_inactive
                                    }
                                >
                                    INDIVIDUAL
                                </span>
                            </label>
                            <label className={s.flex_label}>
                                <CustomCheckbox
                                    handleChange={() => {
                                        setSelectedOptionRecipientType(
                                            "BUSINESS"
                                        );
                                        setFieldValue(
                                            "paymentTypeByRegionForm",
                                            ""
                                        );
                                        setFieldValue(
                                            "recipientType",
                                            "BUSINESS"
                                        );
                                    }}
                                    id="BUSINESS"
                                    name="BUSINESS"
                                    isChecked={
                                        selectedOptionRecipientType ===
                                        "BUSINESS"
                                    }
                                />
                                <span
                                    className={
                                        selectedOptionRecipientType ===
                                        "BUSINESS"
                                            ? s.type_of_payment_active
                                            : s.type_of_payment_inactive
                                    }
                                >
                                    BUSINESS
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            )}

            {selectedOptionRecipientType !== "BUSINESS" &&
                values.paymentRegions === "PAN AFRICA" && (
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S BANK COUNTRY/REGION
                            </div>
                            <SelectWithSearch
                                name={"recipientsBankCountry"}
                                id="recipientsBankCountry"
                                isError={errors.recipientsBankCountry}
                                items={azaCountries}
                                resetAnotherField={true}
                                touched={touched.recipientsBankCountry}
                            />
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>PAYMENT METHOD</div>
                            <CustomDropdown
                                customId="paymentTypeByRegionForm"
                                items={getItemsPaymentType(
                                    values.recipientsBankCountry
                                )}
                                placeholder={"Please select the payment method"}
                                id="paymentTypeByRegionForm"
                                name="paymentTypeByRegionForm"
                                isError={errors.paymentTypeByRegionForm}
                                touched={touched.paymentTypeByRegionForm}
                                setItem={setPaymentTypeByRegion}
                            />
                        </div>
                    </div>
                )}
            {selectedOptionRecipientType === "BUSINESS" &&
                values.paymentRegions === "PAN AFRICA" && (
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S BANK COUNTRY/REGION
                            </div>
                            <SelectWithSearch
                                name={"recipientsBankCountry"}
                                id="recipientsBankCountry"
                                isError={errors.recipientsBankCountry}
                                resetAnotherField={true}
                                items={azaBusinessCountries}
                                touched={touched.recipientsBankCountry}
                            />
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>PAYMENT METHOD</div>
                            <CustomDropdown
                                customId="paymentTypeByRegionForm"
                                items={getBusinessPaymentType(
                                    values.recipientsBankCountry
                                )}
                                placeholder={"Please select the payment method"}
                                id="paymentTypeByRegionForm"
                                name="paymentTypeByRegionForm"
                                isError={errors.paymentTypeByRegionForm}
                                touched={touched.paymentTypeByRegionForm}
                                setItem={setPaymentTypeByRegion}
                            />
                        </div>
                    </div>
                )}

            {/*ACH (only for USD)*/}
            {values.paymentRegions === "ACH (only for USD)" && (
                <div className={s.block}>
                    <div className={s.title}>Recipient’s Details</div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Full Name
                                <Popover
                                    content={
                                        "Enter the name and surname of the recipient or the name of " +
                                        "the legal person."
                                    }
                                    color={"#EEEFEF"}
                                    overlayInnerStyle={{
                                        fontFamily: "Inter",
                                        width: "280px",
                                        padding: "13px 16px",
                                    }}
                                >
                                    <img src={info} alt="" />
                                </Popover>
                            </div>
                            <MyInput
                                id={"fullName"}
                                name={"fullName"}
                                touched={touched.fullName}
                                value={values.fullName}
                                onChange={handleChange}
                                isError={errors.fullName}
                            />
                            {errors.fullName && touched.fullName && (
                                <div className={s.error_message}>
                                    {errors.fullName}
                                </div>
                            )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * INSTITUTION NUMBER
                            </div>
                            <MyInput
                                id={"institutionNumber"}
                                name={"institutionNumber"}
                                touched={touched.institutionNumber}
                                value={values.institutionNumber}
                                onChange={handleChange}
                                isError={errors.institutionNumber}
                            />
                            {errors.institutionNumber &&
                                touched.institutionNumber && (
                                    <div className={s.error_message}>
                                        {errors.institutionNumber}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * TRANSIT NUMBER
                            </div>
                            <MyInput
                                id={"transitNumber"}
                                name={"transitNumber"}
                                touched={touched.transitNumber}
                                value={values.transitNumber}
                                onChange={handleChange}
                                isError={errors.transitNumber}
                            />
                            {errors.transitNumber && touched.transitNumber && (
                                <div className={s.error_message}>
                                    {errors.transitNumber}
                                </div>
                            )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * ACCOUNT NUMBER
                            </div>
                            <MyInput
                                id={"recipientsAccountNumber"}
                                name={"recipientsAccountNumber"}
                                touched={touched.recipientsAccountNumber}
                                value={values.recipientsAccountNumber}
                                onChange={handleChange}
                                isError={errors.recipientsAccountNumber}
                            />
                            {errors.recipientsAccountNumber &&
                                touched.recipientsAccountNumber && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAccountNumber}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S ADDRESS
                            </div>
                            <MyInput
                                id={"recipientsAddress"}
                                name={"recipientsAddress"}
                                touched={touched.recipientsAddress}
                                value={values.recipientsAddress}
                                onChange={handleChange}
                                isError={errors.recipientsAddress}
                            />
                            {errors.recipientsAddress &&
                                touched.recipientsAddress && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAddress}
                                    </div>
                                )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S BANK COUNTRY
                            </div>
                            <SelectWithSearch
                                name={"recipientsBankCountry"}
                                id="recipientsBankCountry"
                                isError={errors.recipientsBankCountry}
                                items={countries}
                                touched={touched.recipientsBankCountry}
                            />
                            {errors.recipientsBankCountry &&
                                touched.recipientsBankCountry && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankCountry}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                recipient’s bank
                            </div>
                            <MyInput
                                id={"recipientsBank"}
                                name={"recipientsBank"}
                                touched={touched.recipientsBank}
                                value={values.recipientsBank}
                                onChange={handleChange}
                                isError={errors.recipientsBank}
                            />
                            {errors.recipientsBank &&
                                touched.recipientsBank && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBank}
                                    </div>
                                )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S BANK ADDRESS
                            </div>
                            <MyInput
                                id={"recipientsBankAddress"}
                                name={"recipientsBankAddress"}
                                touched={touched.recipientsBankAddress}
                                value={values.recipientsBankAddress}
                                onChange={handleChange}
                                isError={errors.recipientsBankAddress}
                            />
                            {errors.recipientsBankAddress &&
                                touched.recipientsBankAddress && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankAddress}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.mobile_row}>
                            <div className={classnames(s.input_block, s.short)}>
                                <div className={s.input_label}>
                                    Email
                                    <Popover
                                        content={
                                            "Please indicate the recipient’s email address and language, " +
                                            "if you would like us to send them an electronically signed automatic email " +
                                            "about the performed transfer."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"email"}
                                    name={"email"}
                                    touched={touched.email}
                                    value={values.email}
                                    onChange={handleChange}
                                    isError={errors.email}
                                />
                            </div>

                            <div className={s.languages_dropdown}>
                                <CustomDropdown
                                    items={languages}
                                    id="emailLanguage"
                                    name="emailLanguage"
                                    isError={errors.emailLanguage}
                                    touched={touched.emailLanguage}
                                />
                            </div>
                        </div>

                        <div className={s.input_block_fixed}>
                            <div className={s.input_label}>UNIQUE ID</div>
                            <MyInput
                                id={"uniqueId"}
                                name={"uniqueId"}
                                touched={touched.uniqueId}
                                value={values.uniqueId}
                                onChange={handleChange}
                                isError={errors.uniqueId}
                            />
                            {errors.uniqueId && touched.uniqueId && (
                                <div className={s.error_message}>
                                    {errors.uniqueId}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/*BACS (only for GBP)*/}
            {values.paymentRegions === "BACS (only for GBP)" && (
                <div className={s.block}>
                    <div className={s.title}>Recipient’s Details</div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>* SORT CODE</div>
                            <MyInput
                                id={"sortCode"}
                                name={"sortCode"}
                                touched={touched.sortCode}
                                value={values.sortCode}
                                onChange={handleChange}
                                isError={errors.sortCode}
                            />
                            {errors.sortCode && touched.sortCode && (
                                <div className={s.error_message}>
                                    {errors.sortCode}
                                </div>
                            )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * ACCOUNT NUMBER
                            </div>
                            <MyInput
                                id={"recipientsAccountNumber"}
                                name={"recipientsAccountNumber"}
                                touched={touched.recipientsAccountNumber}
                                value={values.recipientsAccountNumber}
                                onChange={handleChange}
                                isError={errors.recipientsAccountNumber}
                            />
                            {errors.recipientsAccountNumber &&
                                touched.recipientsAccountNumber && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAccountNumber}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>* IBAN</div>
                            <MyInput
                                id={"recipientsIban"}
                                name={"recipientsIban"}
                                touched={touched.recipientsIban}
                                value={values.recipientsIban}
                                onChange={handleChange}
                                isError={errors.recipientsIban}
                            />
                            {errors.recipientsIban &&
                                touched.recipientsIban && (
                                    <div className={s.error_message}>
                                        {errors.recipientsIban}
                                    </div>
                                )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * SWIFT / BIC code
                            </div>
                            <MyInput
                                id={"recipientsSwiftBic"}
                                name={"recipientsSwiftBic"}
                                touched={touched.recipientsSwiftBic}
                                value={values.recipientsSwiftBic}
                                onChange={handleChange}
                                isError={errors.recipientsSwiftBic}
                            />
                            {errors.recipientsSwiftBic &&
                                touched.recipientsSwiftBic && (
                                    <div className={s.error_message}>
                                        {errors.recipientsSwiftBic}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>FULL NAME</div>
                            <MyInput
                                id={"fullName"}
                                name={"fullName"}
                                touched={touched.fullName}
                                value={values.fullName}
                                onChange={handleChange}
                                isError={errors.fullName}
                            />
                            {errors.fullName && touched.fullName && (
                                <div className={s.error_message}>
                                    {errors.fullName}
                                </div>
                            )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S ADDRESS
                            </div>
                            <MyInput
                                id={"recipientsAddress"}
                                name={"recipientsAddress"}
                                touched={touched.recipientsAddress}
                                value={values.recipientsAddress}
                                onChange={handleChange}
                                isError={errors.recipientsAddress}
                            />
                            {errors.recipientsAddress &&
                                touched.recipientsAddress && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAddress}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block_fixed}>
                            <div className={s.input_label}>
                                RECIPIENT’S BANK COUNTRY
                            </div>
                            <SelectWithSearch
                                name={"recipientsBankCountry"}
                                id="recipientsBankCountry"
                                isError={errors.recipientsBankCountry}
                                items={countries}
                                touched={touched.recipientsBankCountry}
                            />
                            {errors.recipientsBankCountry &&
                                touched.recipientsBankCountry && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankCountry}
                                    </div>
                                )}
                        </div>

                        <div className={s.input_block_fixed}>
                            <div className={s.input_label}>
                                recipient’s bank
                            </div>
                            <MyInput
                                id={"recipientsBank"}
                                name={"recipientsBank"}
                                touched={touched.recipientsBank}
                                value={values.recipientsBank}
                                onChange={handleChange}
                                isError={errors.recipientsBank}
                            />
                            {errors.recipientsBank &&
                                touched.recipientsBank && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBank}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block_fixed}>
                            <div className={s.input_label}>
                                recipient’s bank address
                            </div>
                            <MyInput
                                id={"recipientsBankAddress"}
                                name={"recipientsBankAddress"}
                                touched={touched.recipientsBankAddress}
                                value={values.recipientsBankAddress}
                                onChange={handleChange}
                                isError={errors.recipientsBankAddress}
                            />
                            {errors.recipientsBankAddress &&
                                touched.recipientsBankAddress && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankAddress}
                                    </div>
                                )}
                        </div>

                        <div className={s.mobile_row}>
                            <div className={classnames(s.input_block, s.short)}>
                                <div className={s.input_label}>
                                    Email
                                    <Popover
                                        content={
                                            "Please indicate the recipient’s email address and language, " +
                                            "if you would like us to send them an electronically signed automatic email " +
                                            "about the performed transfer."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"email"}
                                    name={"email"}
                                    touched={touched.email}
                                    value={values.email}
                                    onChange={handleChange}
                                    isError={errors.email}
                                />
                            </div>

                            <div className={s.languages_dropdown}>
                                <CustomDropdown
                                    items={languages}
                                    id="emailLanguage"
                                    name="emailLanguage"
                                    isError={errors.emailLanguage}
                                    touched={touched.emailLanguage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/*CHAPS (only for GBP) or FASTER PAYMENT (only for GBP)*/}
            {(values.paymentRegions === "CHAPS (only for GBP)" ||
                values.paymentRegions === "FASTER PAYMENT (only for GBP)") && (
                <div className={s.block}>
                    <div className={s.title}>Recipient’s Details</div>
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S TYPE
                            </div>
                            <div className={s.flex_label}>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() => {
                                            setSelectedOptionRecipientType(
                                                "INDIVIDUAL"
                                            );
                                            setFieldValue(
                                                "paymentTypeByRegionForm",
                                                ""
                                            );
                                            setFieldValue(
                                                "recipientType",
                                                "INDIVIDUAL"
                                            );
                                        }}
                                        id="INDIVIDUAL"
                                        name="INDIVIDUAL"
                                        isChecked={
                                            selectedOptionRecipientType ===
                                            "INDIVIDUAL"
                                        }
                                    />
                                    <span
                                        className={
                                            selectedOptionRecipientType ===
                                            "INDIVIDUAL"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        INDIVIDUAL
                                    </span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() => {
                                            setSelectedOptionRecipientType(
                                                "BUSINESS"
                                            );
                                            setFieldValue(
                                                "paymentTypeByRegionForm",
                                                ""
                                            );
                                            setFieldValue(
                                                "recipientType",
                                                "BUSINESS"
                                            );
                                        }}
                                        id="BUSINESS"
                                        name="BUSINESS"
                                        isChecked={
                                            selectedOptionRecipientType ===
                                            "BUSINESS"
                                        }
                                    />
                                    <span
                                        className={
                                            selectedOptionRecipientType ===
                                            "BUSINESS"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        BUSINESS
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {selectedOptionRecipientType === "INDIVIDUAL" && (
                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * First Name
                                    <Popover
                                        content={
                                            "Enter the name of the recipient or the name of " +
                                            "the legal person."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"firstName"}
                                    name={"firstName"}
                                    touched={touched.firstName}
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isError={errors.firstName}
                                />
                                {errors.firstName && touched.firstName && (
                                    <div className={s.error_message}>
                                        {errors.firstName}
                                    </div>
                                )}
                            </div>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Last Name
                                    <Popover
                                        content={
                                            "Enter the surname of the recipient or the name of " +
                                            "the legal person."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"lastName"}
                                    name={"lastName"}
                                    touched={touched.lastName}
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName && (
                                    <div className={s.error_message}>
                                        {errors.lastName}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedOptionRecipientType === "BUSINESS" && (
                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Company Name
                                    <Popover
                                        content={"Enter the name of the recipient's company"}
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"companyName"}
                                    name={"companyName"}
                                    touched={touched.companyName}
                                    value={values.companyName}
                                    onChange={handleChange}
                                    isError={errors.companyName}
                                />
                                {errors.companyName && touched.companyName && (
                                    <div className={s.error_message}>
                                        {errors.companyName}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>* SORT CODE</div>
                            <MyInput
                                id={"sortCode"}
                                name={"sortCode"}
                                touched={touched.sortCode}
                                value={values.sortCode}
                                onChange={handleChange}
                                isError={errors.sortCode}
                            />
                            {errors.sortCode && touched.sortCode && (
                                <div className={s.error_message}>
                                    {errors.sortCode}
                                </div>
                            )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * ACCOUNT NUMBER
                            </div>
                            <MyInput
                                id={"recipientsAccountNumber"}
                                name={"recipientsAccountNumber"}
                                touched={touched.recipientsAccountNumber}
                                value={values.recipientsAccountNumber}
                                onChange={handleChange}
                                isError={errors.recipientsAccountNumber}
                            />
                            {errors.recipientsAccountNumber &&
                                touched.recipientsAccountNumber && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAccountNumber}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>IBAN</div>
                            <MyInput
                                id={"recipientsIban"}
                                name={"recipientsIban"}
                                value={values.recipientsIban}
                                onChange={handleChange}
                            />
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                SWIFT / BIC code
                            </div>
                            <MyInput
                                id={"recipientsSwiftBic"}
                                name={"recipientsSwiftBic"}
                                value={values.recipientsSwiftBic}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/*SWIFT / SEPA*/}
            {(values.paymentRegions.toLowerCase() ===
                "swift (international transfer)" ||
                values.paymentRegions === "SEPA (only for EUR)" ||
                values.paymentRegions === "SEPA INSTANT (only for EUR)") && (
                <div className={s.block}>
                    <div className={s.title}>Recipient’s Details</div>
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RECIPIENT’S TYPE
                            </div>
                            <div className={s.flex_label}>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() => {
                                            setSelectedOptionRecipientType(
                                                "INDIVIDUAL"
                                            );
                                            setFieldValue(
                                                "paymentTypeByRegionForm",
                                                ""
                                            );
                                            setFieldValue(
                                                "recipientType",
                                                "INDIVIDUAL"
                                            );
                                        }}
                                        id="INDIVIDUAL"
                                        name="INDIVIDUAL"
                                        isChecked={
                                            selectedOptionRecipientType ===
                                            "INDIVIDUAL"
                                        }
                                    />
                                    <span
                                        className={
                                            selectedOptionRecipientType ===
                                            "INDIVIDUAL"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        INDIVIDUAL
                                    </span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={() => {
                                            setSelectedOptionRecipientType(
                                                "BUSINESS"
                                            );
                                            setFieldValue(
                                                "paymentTypeByRegionForm",
                                                ""
                                            );
                                            setFieldValue(
                                                "recipientType",
                                                "BUSINESS"
                                            );
                                        }}
                                        id="BUSINESS"
                                        name="BUSINESS"
                                        isChecked={
                                            selectedOptionRecipientType ===
                                            "BUSINESS"
                                        }
                                    />
                                    <span
                                        className={
                                            selectedOptionRecipientType ===
                                            "BUSINESS"
                                                ? s.type_of_payment_active
                                                : s.type_of_payment_inactive
                                        }
                                    >
                                        BUSINESS
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {selectedOptionRecipientType === "INDIVIDUAL" && (
                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * First Name
                                    <Popover
                                        content={
                                            "Enter the name of the recipient or the name of " +
                                            "the legal person."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"firstName"}
                                    name={"firstName"}
                                    touched={touched.firstName}
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isError={errors.firstName}
                                />
                                {errors.firstName && touched.firstName && (
                                    <div className={s.error_message}>
                                        {errors.firstName}
                                    </div>
                                )}
                            </div>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Last Name
                                    <Popover
                                        content={
                                            "Enter the surname of the recipient or the name of " +
                                            "the legal person."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"lastName"}
                                    name={"lastName"}
                                    touched={touched.lastName}
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName && (
                                    <div className={s.error_message}>
                                        {errors.lastName}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedOptionRecipientType === "BUSINESS" && (
                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Company Name
                                    <Popover
                                        content={"Enter the name of the recipient's company"}
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"companyName"}
                                    name={"companyName"}
                                    touched={touched.companyName}
                                    value={values.companyName}
                                    onChange={handleChange}
                                    isError={errors.companyName}
                                />
                                {errors.companyName && touched.companyName && (
                                    <div className={s.error_message}>
                                        {errors.companyName}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s country
                            </div>
                            <SelectWithSearch
                                name={"recipientsCountry"}
                                id="recipientsCountry"
                                isError={errors.recipientsCountry}
                                items={countries}
                                touched={touched.recipientsCountry}
                            />
                            {errors.recipientsCountry &&
                                touched.recipientsCountry && (
                                    <div className={s.error_message}>
                                        {errors.recipientsCountry}
                                    </div>
                                )}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                recipient’s state
                            </div>
                            <MyInput
                                id={"beneficiaryState"}
                                name={"beneficiaryState"}
                                touched={touched.beneficiaryState}
                                value={values.beneficiaryState}
                                onChange={handleChange}
                                isError={errors.beneficiaryState}
                            />
                            {errors.beneficiaryState &&
                                touched.beneficiaryState && (
                                    <div className={s.error_message}>
                                        {errors.beneficiaryState}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s city
                            </div>
                            <MyInput
                                id={"recipientsCity"}
                                name={"recipientsCity"}
                                touched={touched.recipientsCity}
                                value={values.recipientsCity}
                                onChange={handleChange}
                                isError={errors.recipientsCity}
                            />
                            {errors.recipientsCity &&
                                touched.recipientsCity && (
                                    <div className={s.error_message}>
                                        {errors.recipientsCity}
                                    </div>
                                )}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s Address
                            </div>
                            <MyInput
                                id={"recipientsAddress"}
                                name={"recipientsAddress"}
                                touched={touched.recipientsAddress}
                                value={values.recipientsAddress}
                                onChange={handleChange}
                                isError={errors.recipientsAddress}
                            />
                            {errors.recipientsAddress &&
                                touched.recipientsAddress && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAddress}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s Zip Code
                            </div>
                            <MyInput
                                id={"recipientsZipCode"}
                                name={"recipientsZipCode"}
                                touched={touched.recipientsZipCode}
                                value={values.recipientsZipCode}
                                onChange={handleChange}
                                isError={errors.recipientsZipCode}
                            />
                            {errors.recipientsZipCode &&
                                touched.recipientsZipCode && (
                                    <div className={s.error_message}>
                                        {errors.recipientsZipCode}
                                    </div>
                                )}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * IBAN / Account number
                                <Popover
                                    content={
                                        "Enter the recipient’s bank account number or IBAN account."
                                    }
                                    color={"#EEEFEF"}
                                    overlayInnerStyle={{
                                        fontFamily: "Inter",
                                        width: "280px",
                                        padding: "13px 16px",
                                    }}
                                >
                                    <img src={info} alt="" />
                                </Popover>
                            </div>
                            <MyInput
                                id={"recipientsAccountNumber"}
                                name={"recipientsAccountNumber"}
                                touched={touched.recipientsAccountNumber}
                                value={values.recipientsAccountNumber}
                                onChange={handleChange}
                                isError={errors.recipientsAccountNumber}
                            />
                            {errors.recipientsAccountNumber &&
                                touched.recipientsAccountNumber && (
                                    <div className={s.error_message}>
                                        {errors.recipientsAccountNumber}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * SWIFT / BIC code
                                <Popover
                                    content={
                                        "A SWIFT code is an international bank code that identifies " +
                                        "particular banks worldwide. It's also known as a Bank Identifier Code (BIC). " +
                                        "A SWIFT code consists of 8 or 11 characters."
                                    }
                                    color={"#EEEFEF"}
                                    overlayInnerStyle={{
                                        fontFamily: "Inter",
                                        width: "280px",
                                        padding: "13px 16px",
                                    }}
                                >
                                    <img src={info} alt="" />
                                </Popover>
                            </div>
                            <MyInput
                                id={"recipientsSwiftBic"}
                                name={"recipientsSwiftBic"}
                                touched={touched.recipientsSwiftBic}
                                value={values.recipientsSwiftBic}
                                onChange={handleChange}
                                isError={errors.recipientsSwiftBic}
                            />
                            {errors.recipientsSwiftBic &&
                                touched.recipientsSwiftBic && (
                                    <div className={s.error_message}>
                                        {errors.recipientsSwiftBic}
                                    </div>
                                )}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s bank
                            </div>
                            <MyInput
                                id={"recipientsBank"}
                                name={"recipientsBank"}
                                touched={touched.recipientsBank}
                                value={values.recipientsBank}
                                onChange={handleChange}
                                isError={errors.recipientsBank}
                            />
                            {errors.recipientsBank &&
                                touched.recipientsBank && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBank}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s bank country
                            </div>
                            <SelectWithSearch
                                name={"recipientsBankCountry"}
                                id="recipientsBankCountry"
                                isError={errors.recipientsBankCountry}
                                items={countries}
                                touched={touched.recipientsBankCountry}
                            />
                            {errors.recipientsBankCountry &&
                                touched.recipientsBankCountry && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankCountry}
                                    </div>
                                )}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                recipient’s bank state
                            </div>
                            <MyInput
                                id={"recipientsBankState"}
                                name={"recipientsBankState"}
                                touched={touched.recipientsBankState}
                                value={values.recipientsBankState}
                                onChange={handleChange}
                                isError={errors.recipientsBankState}
                            />
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s bank city
                            </div>
                            <MyInput
                                id={"recipientsBankCity"}
                                name={"recipientsBankCity"}
                                touched={touched.recipientsBankCity}
                                value={values.recipientsBankCity}
                                onChange={handleChange}
                                isError={errors.recipientsBankCity}
                            />
                            {errors.recipientsBankCity &&
                                touched.recipientsBankCity && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankCity}
                                    </div>
                                )}
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * recipient’s bank address
                            </div>
                            <MyInput
                                id={"recipientsBankAddress"}
                                name={"recipientsBankAddress"}
                                touched={touched.recipientsBankAddress}
                                value={values.recipientsBankAddress}
                                onChange={handleChange}
                                isError={errors.recipientsBankAddress}
                            />
                            {errors.recipientsBankAddress &&
                                touched.recipientsBankAddress && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankAddress}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block_fixed}>
                            <div className={s.input_label}>
                                * recipient’s bank zip code
                            </div>
                            <MyInput
                                id={"recipientsBankZipCode"}
                                name={"recipientsBankZipCode"}
                                touched={touched.recipientsBankZipCode}
                                value={values.recipientsBankZipCode}
                                onChange={handleChange}
                                isError={errors.recipientsBankZipCode}
                            />
                            {errors.recipientsBankZipCode &&
                                touched.recipientsBankZipCode && (
                                    <div className={s.error_message}>
                                        {errors.recipientsBankZipCode}
                                    </div>
                                )}
                        </div>
                        <div className={s.mobile_row}>
                            <div className={classnames(s.input_block, s.short)}>
                                <div className={s.input_label}>
                                    Email
                                    <Popover
                                        content={
                                            "Please indicate the recipient’s email address and language, " +
                                            "if you would like us to send them an electronically signed automatic email " +
                                            "about the performed transfer."
                                        }
                                        color={"#EEEFEF"}
                                        overlayInnerStyle={{
                                            fontFamily: "Inter",
                                            width: "280px",
                                            padding: "13px 16px",
                                        }}
                                    >
                                        <img src={info} alt="" />
                                    </Popover>
                                </div>
                                <MyInput
                                    id={"email"}
                                    name={"email"}
                                    touched={touched.email}
                                    value={values.email}
                                    onChange={handleChange}
                                    isError={errors.email}
                                />
                            </div>

                            <div className={s.languages_dropdown}>
                                <CustomDropdown
                                    items={languages}
                                    id="emailLanguage"
                                    name="emailLanguage"
                                    isError={errors.emailLanguage}
                                    touched={touched.emailLanguage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/*INTERAC (only for CAD)*/}
            {values.paymentRegions === "INTERAC (only for CAD)" && (
                <div className={s.block}>
                    <div className={s.title}>Recipient’s Details</div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Beneficiary Name
                            </div>
                            <MyInput
                                id={"beneficiaryName"}
                                name={"beneficiaryName"}
                                touched={touched.beneficiaryName}
                                value={values.beneficiaryName}
                                onChange={handleChange}
                                isError={errors.beneficiaryName}
                            />
                            {errors.beneficiaryName &&
                                touched.beneficiaryName && (
                                    <div className={s.error_message}>
                                        {errors.beneficiaryName}
                                    </div>
                                )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Beneficiary Email
                            </div>
                            <MyInput
                                id={"beneficiaryEmail"}
                                name={"beneficiaryEmail"}
                                touched={touched.beneficiaryEmail}
                                value={values.beneficiaryEmail}
                                onChange={handleChange}
                                isError={errors.beneficiaryEmail}
                            />
                            {errors.beneficiaryEmail &&
                                touched.beneficiaryEmail && (
                                    <div className={s.error_message}>
                                        {errors.beneficiaryEmail}
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Security Question
                            </div>
                            <MyInput
                                id={"securityQuestion"}
                                name={"securityQuestion"}
                                touched={touched.securityQuestion}
                                value={values.securityQuestion}
                                onChange={handleChange}
                                isError={errors.securityQuestion}
                            />
                            {errors.securityQuestion &&
                                touched.securityQuestion && (
                                    <div className={s.error_message}>
                                        {errors.securityQuestion}
                                    </div>
                                )}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Security Answer
                            </div>
                            <MyInput
                                id={"securityAnswer"}
                                name={"securityAnswer"}
                                touched={touched.securityAnswer}
                                value={values.securityAnswer}
                                onChange={handleChange}
                                isError={errors.securityAnswer}
                            />
                            {errors.securityAnswer &&
                                touched.securityAnswer && (
                                    <div className={s.error_message}>
                                        {errors.securityAnswer}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            )}

            {/*EFT (only for CAD)*/}
            {values.paymentRegions === "EFT (only for CAD)" && (
                <EftForm
                    touched={touched}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                />
            )}

            {/*Bill Payment (only for CAD)*/}
            {values.paymentRegions === "Bill Payment (only for CAD)" && (
                <BillPaymentForm
                    touched={touched}
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                />
            )}

            {
                // Pan africa none business
            }

            {selectedOptionRecipientType !== "BUSINESS" &&
                values.paymentRegions === "PAN AFRICA" && (
                    <PanAfricaIndividual
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        recipientsBankCountry={values.recipientsBankCountry}
                        paymentTypeByRegionForm={values.paymentTypeByRegionForm}
                        // updateParentCode={(value) => setCode(value)}
                    />
                )}

            {selectedOptionRecipientType === "BUSINESS" &&
                values.paymentRegions === "PAN AFRICA" && (
                    <>
                        {values.paymentTypeByRegionForm ===
                            "NigeriaUSD::Bank" && (
                            <NigeriaUsdBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                recipientsBankCountry={
                                    values.recipientsBankCountry
                                }
                                // updateParentCode={(value) => setCode(value)}
                            />
                        )}
                        {values.paymentTypeByRegionForm === "ZAR::Bank" && (
                            <ZarBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                recipientsBankCountry={
                                    values.recipientsBankCountry
                                }
                                // updateParentCode={(value: any) => setCode(value)}
                            />
                        )}
                        {values.paymentTypeByRegionForm === "EGP::Bank" && (
                            <EgpBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                recipientsBankCountry={
                                    values.recipientsBankCountry
                                }
                                // updateParentCode={(value) => setCode(value)}
                            />
                        )}
                        {values.paymentTypeByRegionForm === "GBP::Bank" && (
                            <GbpBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                            />
                        )}

                        {values.paymentTypeByRegionForm === "EUR::Bank" && (
                            <EurBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                            />
                        )}

                        {values.paymentTypeByRegionForm === "XAF::Bank" && (
                            <XafBankBusiness
                                touched={touched}
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                            />
                        )}

                        {values.paymentTypeByRegionForm === "XOF::Bank" && (
                            <XofBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                            />
                        )}

                        {values.paymentTypeByRegionForm === "GHS::Bank" && (
                            <GhsBankBusiness
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                            />
                        )}

                        {values.paymentTypeByRegionForm === "USD::Bank" &&
                            values.paymentRegions === "PAN AFRICA" && (
                                <UsdBankBusiness
                                    touched={touched}
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                />
                            )}

                        {values.paymentTypeByRegionForm === "KES::Bank" &&
                            values.paymentRegions === "PAN AFRICA" && (
                                <KesBankBusiness
                                    touched={props.touched}
                                    values={props.values}
                                    errors={props.errors}
                                    handleChange={props.handleChange}
                                />
                            )}
                    </>
                )}
        </>
    );
};

export default FormBody;
