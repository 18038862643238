import React, { Dispatch, SetStateAction, useState } from "react";
import { motion } from "framer-motion";
import CustomModal from "../ui/CustomModal/CustomModal";
import s from "./NewPassword.module.css";
import MyBtn from "../ui/MyBtn/MyBtn";
import brandConfig from "../../config/config";
import { Formik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAppDispatch } from "../../store/store";
import MyInput from "../ui/MyInput/MyInput";
import attention from "../../assets/images/common/attention.svg";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { newPasswordThunk } from "../../store/reducers/ActionCreators";
import { setDisabled } from "../../store/reducers/UserSlice";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>;
    resetToken: any;
};

const NewPassword = (props: PropsType) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAuthSuccess, setIsAuthSuccess] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const closeAndRedirect = () => {
        setIsModalOpen(false);
        props.setCurrent(0);
        navigate("/login");
    };

    const validationSchema = yup.object({
        password: yup
            .string()
            .min(8, "Your password must be at least 8 characters long.")
            .matches(/[1-9]+/, "Password must contain at least one number")
            .matches(/[a-z]+/, "Password must contain at least one lowercase")
            .matches(/[A-Z]+/, "Password must contain at least one uppercase")
            .required("Required field"),
        confirmPassword: yup
            .string()
            .required("Required field")
            .test(
                "passwords-match",
                "Password and confirm password must be the same",
                function (value) {
                    const { password } = this.parent;
                    return value === password;
                }
            ),
    });

    const onSubmitAction = async (values: any) => {
        console.log('submitted');
        if (!executeRecaptcha) {
            console.log("reCAPTCHA is not ready");
            return;
        }

        const recaptchaToken = await executeRecaptcha("newpass");
        const finalValues = { 
            password: values.password,
            resetToken: props.resetToken,
            recaptchaToken
        };

        dispatch(setDisabled(true));

        dispatch(newPasswordThunk(finalValues))
            .then((res) => {
                if (res.data === "Done") {
                    setIsModalOpen(true);
                }
            })
            .catch((error) => {
                setIsAuthSuccess(false);
                setErrorMessage(error.response.data);
            }).finally(() => dispatch(setDisabled(false)))
    };

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <CustomModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            >
                <div className={s.flex}>
                    <div className={s.modal_title}>Success!</div>
                    <div className={s.modal_subtitle}>
                        The password for your {brandConfig.companyName} account
                        has been successfully changed.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn
                            title={"Ok"}
                            onClick={() => closeAndRedirect()}
                        />
                    </div>
                </div>
            </CustomModal>

            <div className={s.sign_in_wrapper}>
                <Formik
                    initialValues={{ password: "", confirmPassword: "" }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitAction}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.sign_in_content}>
                                <div className={s.title}>New password</div>
                                {!isAuthSuccess && (
                                    <div className={s.error_block}>
                                        <img src={attention} alt="" />
                                        <div>{errorMessage}</div>
                                    </div>
                                )}
                                <div className={s.subtitle}>
                                    Enter your new password for your{" "}
                                    {brandConfig.companyName} account.
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        New Password
                                    </div>
                                    <MyInput
                                        id="password"
                                        name="password"
                                        password
                                        touched={touched.password}
                                        value={values.password}
                                        onChange={handleChange}
                                        isError={errors.password}
                                    />
                                    {errors.password && touched.password && (
                                        <div className={s.error_message}>
                                            {errors.password}
                                        </div>
                                    )}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Confirm Password
                                    </div>
                                    <MyInput
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        password
                                        touched={touched.confirmPassword}
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        isError={errors.confirmPassword}
                                    />
                                    {errors.confirmPassword &&
                                        touched.confirmPassword && (
                                            <div className={s.error_message}>
                                                {errors.confirmPassword}
                                            </div>
                                        )}
                                </div>

                                <div className={s.btn_block}>
                                    <MyBtn title={"Continue"} type={"submit"} />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default NewPassword;
