import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./ToAnIberbancoTransferForm.module.css";
import {DatePicker, Popover} from "antd";
import info from "../../assets/images/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import * as yup from "yup";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";
import {myDate} from "../../helpers/CalculateDate";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import invoice from "../../assets/images/personalAccount/invoice.svg"
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Plus} from "../../assets/images/personalAccount/plus.svg";
import attachedDoc from "../../assets/images/personalAccount/attached_document.svg";
import delete_document from "../../assets/images/personalAccount/delete_document.svg";
import { brandConfig } from "../../config/config";

const validationSchema = yup.object({
    accNumberFrom: yup.string().required('Required field'),
    accNumberTo: yup.string().required('Required field').max(40, 'max 40 symbols'),
    fullName: yup.string().max(100, 'max 100 digits'),
    reference: yup.string().required('Required field').max(100, 'max 100 symbols'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Invalid format")
        .required('Required field')
        .max(10, 'max 10 digits')
        .test(
            'no-leading-zero',
            'invalid format',
            (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => item === '0');


                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }
        ),
    templateName: yup.string().max(100, 'max 100 symbols')
})

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
}


const CurrencyHelper = (props: any) => {
    useEffect(() => {
        props.accountsList.forEach((account: any) => {
            if (account.number === props.accNumberFrom) {
                props.setCurrency(account.currency)
            }
        })
    }, [props.accountsList, props.accNumberFrom, props])

    return null
}

const ToAnIberbancoTransferForm = (props: PropsType) => {

    const [currency, setCurrency] = useState('')
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
    })
    const [isMobile, setIsMobile] = useState(false)

    const accountsList = useAccountsForSelect().filter((account: any) => account.number !== "ALL")

    const transferMethods = [
        'Account number',
        // 'Email',
        // 'Phone number',
    ]

    const languages = [
        'EN',
        'FR',
        'RU',
    ]

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 800,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? "100%" : 311,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today
        return current && current < dayjs().startOf('day');
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Formik initialValues={{
                accNumberFrom: '',
                accNumberTo: '',
                email: '',
                phoneNumber: '',
                emailLanguage: 'EN',
                paymentDate: date.from,
                reference: '',
                templateName: '',
                amount: '',
                transferMethod: 'Account number',
                type: 'internal',
                invoice: '',
            }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {resetForm}) => {

                        if (values.transferMethod === 'Phone number') {
                            // @ts-ignore
                            delete values.recipientsAccountNumber
                        }

                        if (values.transferMethod === 'Email') {
                            // @ts-ignore
                            delete values.phoneNumber
                            // @ts-ignore
                            delete values.recipientsAccountNumber
                        }

                        if (values.transferMethod === 'Account number') {
                            // @ts-ignore
                            delete values.phoneNumber
                        }

                        const tempData = {
                            ...values,
                            transferCurrency: currency,
                            paymentDate: date.from,
                        }

                        props.setTransferData && props.setTransferData(tempData);
                        props.setCurrent && props.setCurrent(1);

                        resetForm({
                            // @ts-ignore
                            values: ''
                        })

                    }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={s.row}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Payer account
                                    <Popover content={"Please select the account you wish to make a transfer from."}
                                             color={'#EEEFEF'}
                                             overlayInnerStyle={{
                                                 fontFamily: 'Inter',
                                                 width: '280px',
                                                 padding: '13px 16px'
                                             }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <CustomDropdownForAccounts
                                    items={accountsList}
                                    placeholder={"Please select the account"}
                                    id="accNumberFrom"
                                    name="accNumberFrom"
                                    isError={errors.accNumberFrom}
                                    touched={touched.accNumberFrom}
                                    setCurrency={setCurrency}
                                    disableZeroBalance={true}
                                />
                                {errors.accNumberFrom &&
                                    <div className={s.error_message}>{errors.accNumberFrom}</div>}
                            </div>

                            <div className={s.input_block}>

                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Recipient’s Details
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Transfer Method
                                        <Popover content={`Choose one of the recipient’s identifiers: their ${brandConfig.companyNameSimple}` +
                                            " account, email address or phone number."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdown
                                        items={transferMethods}
                                        id="transferMethod"
                                        name="transferMethod"
                                        isError={errors.transferMethod}
                                        touched={touched.transferMethod}
                                    />
                                </div>

                                <div className={s.input_block}>
                                    {values.transferMethod === 'Account number'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Account number
                                                <Popover
                                                    content={<span>
                                                        Account number in the format <br/>
                                                        <span className={s.color_popover}>CAIBNCXXXXXXXXXXXXXXX.</span>
                                                    </span>}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"accNumberTo"}
                                                     name={"accNumberTo"}
                                                     touched={touched.accNumberTo}
                                                     value={values.accNumberTo}
                                                     onChange={handleChange}
                                                     isError={errors.accNumberTo}
                                            />
                                            {errors.accNumberTo &&
                                                <div className={s.error_message}>{errors.accNumberTo}</div>}
                                        </div>
                                    }

                                    {values.transferMethod === 'Email'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                *Email
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                        </div>
                                    }

                                    {values.transferMethod === 'Phone number'
                                        && <div className={s.input_block}>
                                            <div className={s.input_label}>

                                                *phone number
                                                <Popover
                                                    content={"Phone number in international format."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"phoneNumber"}
                                                     name={"phoneNumber"}
                                                     touched={touched.phoneNumber}
                                                     value={values.phoneNumber}
                                                     onChange={handleChange}
                                                     isError={errors.phoneNumber}
                                            />
                                        </div>
                                    }

                                </div>
                            </div>

                            <div className={classnames(s.row, s.mobile_row)}>
                                <div className={classnames(s.input_block, s.short)}>
                                    <div className={s.input_label}>
                                        Email
                                        <Popover
                                            content={"Please indicate the recipient’s email address and language, " +
                                                "if you would like us to send them an electronically signed automatic email " +
                                                "about the performed transfer."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Inter',
                                                width: '280px',
                                                padding: '13px 16px'
                                            }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id={"email"}
                                             name={"email"}
                                             touched={touched.email}
                                             value={values.email}
                                             onChange={handleChange}
                                             isError={errors.email}
                                    />
                                </div>

                                <div className={s.languages_dropdown}>
                                    <CustomDropdown
                                        items={languages}
                                        id="emailLanguage"
                                        name="emailLanguage"
                                        isError={errors.emailLanguage}
                                        touched={touched.emailLanguage}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={s.block}>
                            <div className={s.title}>
                                Transfer Details
                            </div>

                            {props.id !== 'create' && <div className={s.row}>
                                <div className={s.input_block_fixed}>
                                    <div className={s.input_label}>
                                        * Payment date
                                        <Popover
                                            content={"Please enter the date of the transfer. The date can be 2 years ahead from today."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Inter',
                                                width: '280px',
                                                padding: '13px 16px'
                                            }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                disabledDate={disabledDate}
                                                value={dayjs(date.from)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, from: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, from: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>
                                </div>

                                <div className={s.mobile_row}>
                                    <div className={classnames(s.input_block, s.short)}>
                                        <div className={s.input_label}>
                                            * Amount
                                            <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                "In the account balance in the selected currency is insufficient, the system " +
                                                "will automatically offer you to top up using funds in a different currency " +
                                                "you have on the account."}
                                                     color={'#EEEFEF'}
                                                     overlayInnerStyle={{
                                                         fontFamily: 'Inter',
                                                         width: '280px',
                                                         padding: '13px 16px'
                                                     }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"amount"}
                                                 name={"amount"}
                                                 touched={touched.amount}
                                                 value={values.amount}
                                                 onChange={handleChange}
                                                 isError={errors.amount}
                                        />
                                        {errors.amount &&
                                            <div className={s.error_message}>{errors.amount}</div>}
                                    </div>

                                    <div className={s.currency_dropdown}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id="transferCurrency"
                                            name="transferCurrency"
                                            value={currency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                            </div>}

                            <div className={s.row}>
                                <div className={s.textarea_block}>
                                    <div className={s.input_label}>
                                        * Reference
                                    </div>
                                    <MyTextarea
                                        id={"reference"}
                                        name={"reference"}
                                        onChange={handleChange}
                                        value={values.reference}
                                        isError={errors.reference}
                                        touched={touched.reference}
                                    />
                                    {errors.reference &&
                                        <div className={s.error_message}>{errors.reference}</div>}
                                </div>
                            </div>

                        </div>

                        {!props.id && <div className={s.block}>
                            <div className={s.title}>
                                Save the Transfer Details as a Template
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        template Name
                                        <Popover
                                            content={"In order to save this transfer as a template, please enter the " +
                                                "template name."}
                                            color={'#EEEFEF'}
                                            overlayInnerStyle={{
                                                fontFamily: 'Inter',
                                                width: '280px',
                                                padding: '13px 16px'
                                            }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <MyInput id={"templateName"}
                                             name={"templateName"}
                                             touched={touched.templateName}
                                             value={values.templateName}
                                             onChange={handleChange}
                                             isError={errors.templateName}
                                    />
                                    {errors.templateName &&
                                        <div className={s.error_message}>{errors.templateName}</div>}
                                </div>

                                <div className={s.input_block}>

                                </div>
                            </div>
                        </div>}

                        {
                            !props.id &&
                            <div className={s.add_dock_block}>
                                <div className={classnames(s.row, s.flex_start)}>
                                    <div className={s.invoice_img}>
                                        <img src={invoice} alt=""/>
                                    </div>
                                    <div>
                                        <p className={s.invoice_title}>Add an invoice (optional)</p>
                                        <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT, PDF.</p>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.button_wrapper}>
                                        <TransparentButton
                                            title={"Add file"}
                                            small
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('hiddenInvoice').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept=".png, .jpg, .jpeg, .pdf, .txt"
                                               id={"hiddenInvoice"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setFieldValue("invoice", event.currentTarget.files[0]);
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            values.invoice && <img src={attachedDoc} alt=""/>
                                        }
                                        {
                                            // @ts-ignore
                                            <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>
                                        }
                                        {
                                            // @ts-ignore
                                            values?.invoice?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("hiddenInvoice").value = ""
                                                setFieldValue("invoice", "")
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={s.continue_block}>
                            <div className={s.required}>
                                <div>
                                    * required fields.
                                </div>
                                <div>
                                    ** one of the fields is required.
                                </div>
                            </div>
                            {!props.id && <div className={s.button_wrapper}>
                                <MyBtn title={"Continue"} type={"submit"}/>
                            </div>}
                        </div>

                        <Persist name="iberbanco-transfer-form"/>

                        <CurrencyHelper
                            accountsList={accountsList}
                            accNumberFrom={values.accNumberFrom}
                            setCurrency={setCurrency}
                        />

                    </form>
                )}
            </Formik>
        </motion.div>
    );
};

export default ToAnIberbancoTransferForm;
