import React, { Dispatch, SetStateAction, useRef } from "react";
import { Checkbox, DatePicker, Popover } from "antd";
import info from "../../../../assets/images/personalAccount/information.svg";
import MyInput from "../../../ui/MyInput/MyInput";
import { Field, useFormikContext } from "formik";
import SelectWithSearch from "../../../ui/SelectWithSearch/SelectWithSearch";
import CustomDropdown from "../../../ui/CustomDropdown/CustomDropdown";
import {
    dateFormat,
    identityCardTypes,
} from "../../../../constants/KycConstants";
import dayjs from "dayjs";
import classnames from "classnames";
import { countries } from "../../../../constants/StaticData";
import CustomCheckbox from "../../../ui/customCheckbox/CustomCheckbox";
import passportSpreadIcon from "../../../../assets/images/personalAccount/passport_spread.png";
import selfieIcon from "../../../../assets/images/personalAccount/selfie.png";
import proofOfAddressIcon from "../../../../assets/images/personalAccount/proof.png";
import idCardIcon from "../../../../assets/images/personalAccount/idCard.png";
import { ReactComponent as Back } from "../../../../assets/images/personalAccount/back.svg";
import brandConfig from "../../../../config/config";
import TransparentButton from "../../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import { IdentityCardTypeHelper } from "../../../../helpers/KycFormHelper";
import MyBtn from "../../../ui/MyBtn/MyBtn";
import { useAppSelector } from "../../../../hooks/redux";
import { getPhoneNumberList, PersonalKycValues } from "../../Constants/KycConstants";
import DocumentBlock from "../../../DocumentBlock/DocumentBlock";

type PropsType = {
    s: any;
    documents: any;
    isPep: any;
    date: any;
    editModeMissingDoc: string[];
    createModeMissingDoc: string[];
    setDate: Dispatch<SetStateAction<any>>;
    setIsPep: Dispatch<SetStateAction<any>>;
    setDocuments: Dispatch<SetStateAction<any>>;
    setCurrent: Dispatch<SetStateAction<any>>;
};

export const KycFormPersonal = (props: PropsType) => {
    const { 
        s, 
        documents,
        isPep, 
        date, 
        createModeMissingDoc, 
        editModeMissingDoc, 
        setIsPep, 
        setDate, 
        setCurrent, 
        setDocuments
    } = props;

    const phoneList = useAppSelector(
        (state) => state.userReducer.user.phoneList
    );

    const { values, errors, touched, handleChange } =
        useFormikContext<PersonalKycValues>();

    const firstNameRef = useRef<any>(null!);

    const sourcesOfWealthHandlerChange = (
        e: any,
        meta: any,
        form: any,
        name: string
    ) => {
        form.setFieldValue("otherSourcesOfWealth", "");
        form.setFieldValue("isOtherSourcesOfWealth", false);
        const { checked } = e.target;
        if (checked) {
            form.setFieldValue("sourcesOfWealth", [
                ...meta.value.sourcesOfWealth,
                name,
            ]);
        } else {
            const filteredArr = meta.value.sourcesOfWealth.filter(
                (i: string) => i !== name
            );
            form.setFieldValue("sourcesOfWealth", filteredArr);
        }
    };

    return (
        <>
            <div className={s.row}>
                <div className={s.input_block} ref={firstNameRef}>
                    <div className={s.input_label}>
                        * First name
                        <Popover
                            content={
                                "Enter your First Name as in the ID document."
                            }
                            color={"#EEEFEF"}
                            overlayInnerStyle={{
                                fontFamily: "Inter",
                                width: "280px",
                                padding: "13px 16px",
                            }}
                        >
                            <img src={info} alt="" />
                        </Popover>
                    </div>
                    <MyInput
                        id="firstName"
                        name="firstName"
                        onChange={handleChange}
                        value={values.firstName}
                        isError={errors.firstName}
                        touched={touched.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                        <div className={s.error_message}>
                            {errors.firstName}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * Last name
                        <Popover
                            content={
                                "Enter your Last Name as in the ID document."
                            }
                            color={"#EEEFEF"}
                            overlayInnerStyle={{
                                fontFamily: "Inter",
                                width: "280px",
                                padding: "13px 16px",
                            }}
                        >
                            <img src={info} alt="" />
                        </Popover>
                    </div>
                    <MyInput
                        id="lastName"
                        name="lastName"
                        onChange={handleChange}
                        value={values.lastName}
                        isError={errors.lastName}
                        touched={touched.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                        <div className={s.error_message}>{errors.lastName}</div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Country</div>
                    <SelectWithSearch
                        id="countryName"
                        name="countryName"
                        isError={errors.countryName}
                        items={countries}
                        touched={touched.countryName}
                    />
                    {errors.countryName && touched.countryName && (
                        <div className={s.error_message}>
                            {errors.countryName}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* Phone number</div>
                    <CustomDropdown
                        items={getPhoneNumberList(phoneList)}
                        placeholder={"Choose your phone number"}
                        id="phoneNumber"
                        name="phoneNumber"
                        isError={errors.phoneNumber}
                        touched={touched.phoneNumber}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                        <div className={s.error_message}>
                            {errors.phoneNumber}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* city</div>
                    <MyInput
                        id="city"
                        name="city"
                        onChange={handleChange}
                        value={values.city}
                        isError={errors.city}
                        touched={touched.city}
                    />
                    {errors.city && touched.city && (
                        <div className={s.error_message}>{errors.city}</div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>state</div>
                    <MyInput
                        id="state"
                        name="state"
                        onChange={handleChange}
                        value={values.state}
                        isError={errors.state}
                        touched={touched.state}
                    />
                    {errors.state && touched.state && (
                        <div className={s.error_message}>{errors.state}</div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Address</div>
                    <MyInput
                        id="address"
                        name="address"
                        onChange={handleChange}
                        value={values.address}
                        isError={errors.address}
                        touched={touched.address}
                    />
                    {errors.address && touched.address && (
                        <div className={s.error_message}>{errors.address}</div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* Postal code</div>
                    <MyInput
                        id="postalCode"
                        name="postalCode"
                        onChange={handleChange}
                        value={values.postalCode}
                        isError={errors.postalCode}
                        touched={touched.postalCode}
                    />
                    {errors.postalCode && touched.postalCode && (
                        <div className={s.error_message}>
                            {errors.postalCode}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Identity card type</div>
                    <CustomDropdown
                        items={identityCardTypes}
                        id="identityCardType"
                        name="identityCardType"
                        isError={errors.identityCardType}
                        touched={touched.identityCardType}
                        disable={values.countryName !== "Canada"}
                    />
                    {errors.identityCardType && touched.identityCardType && (
                        <div className={s.error_message}>
                            {errors.identityCardType}
                        </div>
                    )}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* Identity card id</div>
                    <MyInput
                        id="identityCardId"
                        name="identityCardId"
                        onChange={handleChange}
                        value={values.identityCardId}
                        isError={errors.identityCardId}
                        touched={touched.identityCardId}
                    />
                    {errors.identityCardId && touched.identityCardId && (
                        <div className={s.error_message}>
                            {errors.identityCardId}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Date of Birth</div>
                    <DatePicker
                        suffixIcon={null}
                        className={s.dateStyle}
                        defaultValue={dayjs(new Date(), dateFormat)}
                        value={dayjs(date.from)}
                        onChange={(_, dateString) => {
                            if (!dateString) {
                                setDate({
                                    ...date,
                                    from: new Date().toDateString(),
                                });
                            } else {
                                setDate({ ...date, from: dateString });
                            }
                        }}
                        format={dateFormat}
                    />
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>* Tax Number</div>
                    <MyInput
                        id="taxNumber"
                        name="taxNumber"
                        onChange={handleChange}
                        value={values.taxNumber}
                        isError={errors.taxNumber}
                        touched={touched.taxNumber}
                    />
                    {errors.taxNumber && (
                        <div className={s.error_message}>
                            {errors.taxNumber}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>* Citizenship</div>
                    <SelectWithSearch
                        id="citizenshipName"
                        name="citizenshipName"
                        isError={errors.citizenshipName}
                        items={countries}
                        touched={touched.citizenshipName}
                    />
                    {errors.citizenshipName && touched.citizenshipName && (
                        <div className={s.error_message}>
                            {errors.citizenshipName}
                        </div>
                    )}
                </div>
                <div className={s.input_block}></div>
            </div>

            <div className={s.row}>
                <div className={classnames(s.input_block)}>
                    <div className={s.input_label}>
                        * Sources of wealth or income
                    </div>
                    <div
                        role="group"
                        aria-labelledby="checkbox-group"
                        className={s.sources_of_wealth_wrapper}
                    >
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Inheritances"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Inheritances"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Inheritances
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Investments"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Investments"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Investments
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Business ownership interests"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Business ownership interests"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Business ownership interests
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Employment income"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Employment income"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Employment income
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Personal savings"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Personal savings"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Personal savings
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Pension releases"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Pension releases"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Pension releases
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Share sales and dividends"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Share sales and dividends"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Share sales and dividends
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Property sales"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Property sales"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Property sales
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Gambling winnings"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Gambling winnings"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Gambling winnings
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Inheritances and gifts"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Inheritances and gifts"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Inheritances and gifts
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={props?.meta?.value?.sourcesOfWealth?.includes(
                                                "Compensation from legal rulings"
                                            )}
                                            onChange={(e) => {
                                                sourcesOfWealthHandlerChange(
                                                    e,
                                                    props.meta,
                                                    props.form,
                                                    "Compensation from legal rulings"
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>
                                Compensation from legal rulings
                            </span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field>
                                {(props: any) => {
                                    return (
                                        <Checkbox
                                            checked={
                                                props.meta.value
                                                    .isOtherSourcesOfWealth
                                            }
                                            onChange={(e) => {
                                                const { checked } = e.target;
                                                if (checked) {
                                                    props.form.setFieldValue(
                                                        "sourcesOfWealth",
                                                        []
                                                    );
                                                }
                                                props.form.setFieldValue(
                                                    "isOtherSourcesOfWealth",
                                                    checked
                                                );
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                            <span className={s.checkbox_label}>Other</span>
                        </label>
                    </div>
                    {errors.sourcesOfWealth &&
                        values?.sourcesOfWealth?.length === 0 && (
                            <div className={s.error_message}>
                                {errors.sourcesOfWealth}
                            </div>
                        )}
                </div>
            </div>

            <div className={s.row}>
                {
                    // @ts-ignore
                    values.isOtherSourcesOfWealth && (
                        <div className={s.input_block}>
                            <div className={s.input_label}>* Other</div>
                            <MyInput
                                id="otherSourcesOfWealth"
                                name="otherSourcesOfWealth"
                                onChange={handleChange}
                                value={values.otherSourcesOfWealth}
                                isError={errors.otherSourcesOfWealth}
                                touched={touched.otherSourcesOfWealth}
                            />
                            {errors.otherSourcesOfWealth && (
                                <div className={s.error_message}>
                                    {errors.otherSourcesOfWealth}
                                </div>
                            )}
                        </div>
                    )
                }
                <div className={s.input_block}></div>
            </div>

            <div className={s.row}>
                <div className={classnames(s.input_block)}>
                    <div
                        className={classnames(
                            s.input_label,
                            errors.currencies && s.doc_error
                        )}
                        id="checkbox-group"
                    >
                        * Add a new account
                    </div>
                    <div
                        role="group"
                        aria-labelledby="checkbox-group"
                        className={s.checkbox_wrapper}
                    >
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="CAD"
                            />
                            <span className={s.checkbox_label}>CAD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="EUR"
                            />
                            <span className={s.checkbox_label}>EUR</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="GBP"
                            />
                            <span className={s.checkbox_label}>GBP</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="USD"
                            />
                            <span className={s.checkbox_label}>USD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="USDT"
                            />
                            <span className={s.checkbox_label}>USDT</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="SGD"
                            />
                            <span className={s.checkbox_label}>SGD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="HKD"
                            />
                            <span className={s.checkbox_label}>HKD</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="JPY"
                            />
                            <span className={s.checkbox_label}>JPY</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="CNH"
                            />
                            <span className={s.checkbox_label}>CNH</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="AED"
                            />
                            <span className={s.checkbox_label}>AED</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="NGN"
                            />
                            <span className={s.checkbox_label}>NGN</span>
                        </label>
                        <label className={s.checkbox_block}>
                            <Field
                                as={Checkbox}
                                type="checkbox"
                                name="currencies"
                                value="CNY"
                            />
                            <span className={s.checkbox_label}>CNY</span>
                        </label>
                    </div>
                    {errors.currencies && (
                        <div className={s.error_message}>
                            {errors.currencies}
                        </div>
                    )}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * Politically exposed person (pep)
                    </div>
                    <div className={classnames(s.flex_label, s.radio_block)}>
                        <label className={s.flex_label}>
                            <CustomCheckbox
                                handleChange={() => setIsPep(false)}
                                id="No"
                                name="No"
                                isChecked={!isPep}
                            />
                            <span className={s.type_of_account}>No</span>
                        </label>
                        <label className={s.flex_label}>
                            <CustomCheckbox
                                handleChange={() => setIsPep(true)}
                                id="Yes"
                                name="Yes"
                                isChecked={isPep}
                            />
                            <span className={s.type_of_account}>Yes</span>
                        </label>
                    </div>
                </div>
            </div>

            <div className={s.line}></div>

            <DocumentBlock
                s={s}
                title="Passport spread with your photo"
                documentType="passportSpread"
                isRequired={true}
                iconUrl={passportSpreadIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={8}
                allowedFileTypes={[".png", ".jpg", ".jpeg", ".pdf"]}
                descriptionItems={[
                    "A color photo contains full document in clear focus and the data can be read easily.",
                    "There're no light glares impeding document readability.",
                    "A photo was not edited in graphic applications (we will definitely check)."
                ]}
            />

            <DocumentBlock
                s={s}
                title="Selfie with your Passport"
                documentType="selfie"
                isRequired={true}
                iconUrl={selfieIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={[".pdf", "image/png", "image/jpeg"]}
                descriptionText={`
                    Upload a selfie of the Account Holder holding his
                    Passport (at the page with a photo) in one hand and
                    a paper with written name of platform "
                    ${brandConfig.companyNameSimple}", date (dd/mm/yyyy/)
                    and Account Holder's signature in the other hand.
                    Photo should be good enough so it is possible to
                    read ID data and see written on the paper text. You
                    can use help of another person to make this photo or
                    use a delayed photo function on your phone.
                `}
            />

            <DocumentBlock
                s={s}
                title="Personal Proof of Address"
                documentType="proofOfAddress"
                isRequired={true}
                iconUrl={proofOfAddressIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["image/png", "image/jpeg", "application/pdf"]}
                descriptionItems={[
                    "Bank statement with the date of issue and the name of the person (the document must be not older than 3 months).",
                    "Utility bill for gas, electricity, water, internet, etc. linked to the property (the document must not be older than 3 months)."
                ]}
            />

            <DocumentBlock
                s={s}
                title="ID Card/Residence Permit Card"
                documentType="idCard"
                isRequired={false}
                iconUrl={idCardIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["image/png", "image/jpeg", ".pdf"]}
                descriptionText="If you have National ID Card or Residence Permit Card, please upload it."
            />

            <DocumentBlock
                s={s}
                title="Back of ID Card/Residence Permit Card"
                documentType="backOfIdCard"
                isRequired={false}
                iconUrl={idCardIcon}
                createModeMissingDoc={createModeMissingDoc}
                editModeMissingDoc={editModeMissingDoc}
                documents={documents}
                setDocuments={setDocuments}
                maxFileSize={10}
                allowedFileTypes={["image/png", "image/jpeg", ".pdf"]}
                descriptionText="If you uploaded ID Card/Driving license, please upload the back side of it."
            />

            <div className={s.btn_block}>
                <div className={s.button_back_wrapper}>
                    <TransparentButton
                        title={"Back"}
                        large
                        style={{ width: "100%" }}
                        onClick={() => setCurrent(0)}
                        icon={
                            <Icon component={() => <Back />} rev={undefined} />
                        }
                    />
                </div>
                <div className={s.button_apply_wrapper}>
                    <MyBtn title={"Apply"} type={"submit"} />
                </div>
            </div>

            <IdentityCardTypeHelper
                country={values.countryName}
                identityCardType={values.identityCardType}
                name={"identityCardType"}
            />
        </>
    );
};
