import React, {Dispatch, SetStateAction, useState} from 'react';
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import s from "./CardsStepFour.module.css";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import {Formik} from "formik";
import {phonecodes} from "../../../../constants/StaticData";
import {setDisabled} from "../../../../store/reducers/UserSlice";
import {getCardsInfo, sendPhysicalCardRequest} from "../../../../store/reducers/ActionCreators";


type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
    selectedCard: any;
}

const CardsStepFour = (props: PropsType) => {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const token = useAppSelector(shippingCountyState => shippingCountyState.userReducer.user.userToken)
    const dispatch = useAppDispatch()

    const closeAndRedirect = () => {
        setIsSuccessModalOpen(false)
        props.setCurrent(2)
    }

    const getCountryName = (countryCode: string) => {
        const country = phonecodes.find((entry) => entry.co === countryCode);
        return country?.na;
    };
    const country = getCountryName(props.selectedCard.shippingAddress.DelvCountryCode ?? '')


    return (<>
        {<div className={s.pageWrapper}>

            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div>
                    <div className={s.modal_title}>Success!</div>
                    <span className={s.success_subtitle}> Your request has been sent.</span>
                    <div className={s.success_button_wrapper}>
                        <div className={s.btn_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </div>

            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={s.popup_title}>
                    Oops!
                </div>
                <div className={s.popup_subtitle}>
                    Something went wrong. Please try again later.
                </div>
                <div className={s.popup_button_wrapper}>
                    <div className={s.btn_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <Formik initialValues={{
                san: props.selectedCard.san,
            }}
                    onSubmit={(values) => {
                        const tempData = {
                            ...values
                        };
                        dispatch(setDisabled(true))
                        dispatch(sendPhysicalCardRequest(token, tempData))
                            .then((res) => {
                                dispatch(getCardsInfo(token))
                                setIsSuccessModalOpen(true);
                            })
                            .catch((e) => {
                                setIsErrorModalOpen(true)
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })

                    }}>
                {({
                      values, errors, touched, handleChange, handleSubmit
                  }) => (<form onSubmit={handleSubmit}>
                    <div className={s.pageTitle}>
                        Request Physical Card
                    </div>
                    <div className={s.span}>
                        Please note that an additional fee of 00.00 EUR will apply to this operation.
                    </div>
                    <div className={s.formWrapper}>
                        <div className={s.inputTitle}>
                            PREPAID CARD
                        </div>
                        <div className={s.inputText}>
                            <div className={s.masterCard}></div>
                            {props.selectedCard.cardNumber}
                        </div>
                        <div className={s.inputTitle}>
                            CARD OWNER
                        </div>
                        <div className={s.inputText}>
                            {props.selectedCard.cardHolder}
                        </div>
                        <div className={s.inputTitle}>
                            SHIPPING ADDRESS
                        </div>
                        <div className={s.inputText}>
                            {props.selectedCard.shippingAddress.DelvAddress1} <br/>
                            {props.selectedCard.shippingAddress.DelvCity}<br/>
                            {props.selectedCard.shippingAddress.DelvCountyState !== null ? (<>
                                {props.selectedCard.shippingAddress.DelvCountyState}
                                <br/>
                            </>) : ('')}
                            {country}<br/>
                            {props.selectedCard.shippingAddress.DelvPostCode}
                        </div>
                        <div className={s.inputTitle}>
                            CONTACT INFORMATION
                        </div>
                        <div className={s.inputText}>
                            {props.selectedCard.email}<br/>
                            {props.selectedCard.phone}
                        </div>
                        <div className={s.inputTitle}>
                            PHYSICAL CARD REQUEST FEE
                        </div>
                        <div className={s.inputText}>
                            00.00 {props.selectedCard.currency}
                        </div>
                        <div className={s.inputTitle}>
                            SHIPPING METHODS
                        </div>
                        <div className={s.inputText}>
                            {props.selectedCard.shippingAddress.Delivery}
                        </div>
                        <div className={s.inputTitle}>

                        </div>
                        <div className={s.inputText}>
                            delivery fee: 00.00 {props.selectedCard.currency}
                        </div>
                        <div className={s.formSpan}>
                            Please check your profile information. If needed, please
                            <NavLink to={`/personal_account/personal/settings`}>
                                <div className={s.link}> Edit Your Profile.
                                </div>
                            </NavLink>
                        </div>
                        <div className={s.buttons_wrapper}>
                            <MyBtn
                                large
                                style={{width: 180}}
                                title={'Agree'}
                            />
                            <MyBtn
                                large
                                style={{width: 180}}
                                title={'Cancel'}
                                onClick={() => props.setCurrent(2)}
                            />
                        </div>
                    </div>

                </form>)}
            </Formik>
        </div>}
    </>);
};

export default CardsStepFour;