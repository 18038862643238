import React, {useState} from 'react';
import MyInput from "../ui/MyInput/MyInput";
import s from "../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {
    bankAccountTypesTED,
    bankCodeListEGPBank,
    bankCodeListGhanaGHSBank, bankCodeListKESBank,
    bankCodeListNgnBank,
    bankCodeListNigeriaUSDBank, bankCodeListTEDPayments, bankCodeListZARBank, branchCodeListBWPBank,
    branchCodeListUGXBank, branchCodeListZMWBank, egyptUsdBankCountries, idTypesKenya,
    idTypesMorocco,
    mobileProvidersGhana, mobileProvidersGuinea, mobileProvidersKenya,
    mobileProvidersUganda,
    mobileProvidersXAF, pixKeyTypesBrazil,
    transferReasons,
    xafMobileCountries,
    xofCashCountries,
    xofMobileCountries
} from "../../constants/StaticData";
import PhoneCodesInput from "../ui/PhoneCodesInput/PhoneCodesInput";
import {getMobileProvider, getPhoneCode} from "../BankTransferFormPersonal/FormHelpers/FormHelpers";
import {Popover} from "antd";
import info from "../../assets/images/personalAccount/information.svg";

interface FormikProps {
    values: any;
    errors: any;
    touched: any;
    handleChange: (e: React.ChangeEvent<any>) => void;
    paymentTypeByRegionForm :any;
    recipientsBankCountry: any;
    updateParentCode?: (value: any) => any; // Function that accepts a string

}

const NigeriaUsdBank: React.FC<FormikProps> = ({
                                                   values,
                                                   errors,
                                                   touched,
                                                   handleChange,
                                                   recipientsBankCountry,
                                                   paymentTypeByRegionForm,
                                                   updateParentCode

                                               }) => {


    const [code , setCode] = useState<any>({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    });



    if (getPhoneCode(recipientsBankCountry, null) !== code){
        setCode(getPhoneCode(recipientsBankCountry, null))
        updateParentCode && updateParentCode(getPhoneCode(recipientsBankCountry, null))
    }



    return (
        <>

            {/*PAN AFRICA NGN::Bank*/}
            {
                (paymentTypeByRegionForm === "NGN::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK CODE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"bankCode"}
                                name={"bankCode"}
                                touched={touched.bankCode}
                                isError={errors.bankCode && errors.bankCode.name}
                                items={bankCodeListNgnBank}
                            />
                            {errors.bankCode && touched.bankCode &&
                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"bankAccount"}
                                     name={"bankAccount"}
                                     touched={touched.bankAccount}
                                     value={values.bankAccount}
                                     onChange={handleChange}
                                     isError={errors.bankAccount}
                            />
                            {errors.bankAccount && touched.bankAccount &&
                                <div className={s.error_message}>{errors.bankAccount}</div>}
                        </div>
                    </div>
                </div>
            }

            {/*PAN AFRICA NGN::Mobile*/}
            {
                (paymentTypeByRegionForm === "NGN::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["ng"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>

                        </div>
                    </div>
                </div>
            }

            {/*PAN AFRICA NigeriaUSD::Bank*/}
            {
                (paymentTypeByRegionForm === "NigeriaUSD::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK CODE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"bankCode"}
                                name={"bankCode"}
                                touched={touched.bankCode}
                                isError={errors.bankCode && errors.bankCode.name}
                                items={bankCodeListNigeriaUSDBank}
                            />
                            {errors.bankCode && touched.bankCode &&
                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"bankAccount"}
                                     name={"bankAccount"}
                                     touched={touched.bankAccount}
                                     value={values.bankAccount}
                                     onChange={handleChange}
                                     isError={errors.bankAccount}
                            />
                            {errors.bankAccount && touched.bankAccount &&
                                <div className={s.error_message}>{errors.bankAccount}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * PHONE NUMBER
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={['ng']}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>
                </div>
            }

            {/*PAN AFRICA NigeriaUSD::Cash*/}
            {
                (paymentTypeByRegionForm === "NigeriaUSD::Cash" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * PHONE NUMBER
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["ng"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>
                </div>
            }

            {/*PAN AFRICA GHS::Bank*/}
            {
                (paymentTypeByRegionForm === "GHS::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"bankAccount"}
                                     name={"bankAccount"}
                                     touched={touched.bankAccount}
                                     value={values.bankAccount}
                                     onChange={handleChange}
                                     isError={errors.bankAccount}
                            />
                            {errors.bankAccount && touched.bankAccount &&
                                <div className={s.error_message}>{errors.bankAccount}</div>}
                        </div>
                    </div>

                    <div className={s.row}>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK CODE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"bankCode"}
                                name={"bankCode"}
                                touched={touched.bankCode}
                                isError={errors.bankCode && errors.bankCode.name}
                                items={bankCodeListGhanaGHSBank}
                            />
                            {errors.bankCode && touched.bankCode &&
                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                        </div>

                        <div className={s.input_block}>
                        </div>

                    </div>
                </div>
            }

            {/*PAN AFRICA GHS::Mobile*/}
            {
                (paymentTypeByRegionForm === "GHS::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <PhoneCodesInput
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        error={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                        selectable = {false}
                                        onlyCountries={["gh"]}
                                    />
                                    {errors.phoneNumber &&
                                        <div
                                            className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * MOBILE PROVIDER
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"mobileProvider"}
                                name={"mobileProvider"}
                                touched={touched.mobileProvider}
                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                items={mobileProvidersGhana}
                            />
                            {errors.mobileProvider && touched.mobileProvider &&
                                <div
                                    className={s.error_message}>{errors.mobileProvider.name}</div>}
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA GHS::Cash*/}
            {
                (paymentTypeByRegionForm === "GHS::Cash" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <PhoneCodesInput
                                        id='phoneNumber'
                                        name='phoneNumber'
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        error={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                        selectable = {false}
                                        onlyCountries={["gh"]}
                                    />
                                    {errors.phoneNumber &&
                                        <div
                                            className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                        </div>

                        <div className={s.input_block}>

                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA UGX::Bank*/}
            {
                (paymentTypeByRegionForm === "UGX::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * STREET
                            </div>
                            <MyInput id={"street"}
                                     name={"street"}
                                     touched={touched.street}
                                     value={values.street}
                                     onChange={handleChange}
                                     isError={errors.street}
                            />
                            {errors.street && touched.street &&
                                <div className={s.error_message}>{errors.street}</div>}

                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * CITY
                            </div>
                            <MyInput id={"city"}
                                     name={"city"}
                                     touched={touched.city}
                                     value={values.city}
                                     onChange={handleChange}
                                     isError={errors.city}
                            />
                            {errors.city && touched.city &&
                                <div className={s.error_message}>{errors.city}</div>}

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                POSTAL CODE
                            </div>
                            <MyInput id={"postalCode"}
                                     name={"postalCode"}
                                     touched={touched.postalCode}
                                     value={values.postalCode}
                                     onChange={handleChange}
                                     isError={errors.postalCode}
                            />
                            {errors.postalCode && touched.postalCode &&
                                <div className={s.error_message}>{errors.postalCode}</div>}

                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * IDENTITY CARD ID
                            </div>
                            <MyInput id={"identityCardId"}
                                     name={"identityCardId"}
                                     touched={touched.identityCardId}
                                     value={values.identityCardId}
                                     onChange={handleChange}
                                     isError={errors.identityCardId}
                            />
                            {errors.identityCardId && touched.identityCardId &&
                                <div className={s.error_message}>{errors.identityCardId}</div>}

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"bankAccount"}
                                     name={"bankAccount"}
                                     touched={touched.bankAccount}
                                     value={values.bankAccount}
                                     onChange={handleChange}
                                     isError={errors.bankAccount}
                            />
                            {errors.bankAccount && touched.bankAccount &&
                                <div className={s.error_message}>{errors.bankAccount}</div>}

                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * BRANCH CODE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"branchCode"}
                                name={"branchCode"}
                                touched={touched.branchCode}
                                isError={errors.branchCode && errors.branchCode.name}
                                items={branchCodeListUGXBank}
                            />
                            {errors.branchCode && touched.branchCode &&
                                <div className={s.error_message}>{errors.branchCode.name}</div>}
                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_label}>
                                * TRANSFER REASON
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"transferReason"}
                                name={"transferReason"}
                                touched={touched.transferReason}
                                isError={errors.transferReason && errors.transferReason.name}
                                items={transferReasons}
                            />
                            {errors.transferReason && touched.transferReason &&
                                <div
                                    className={s.error_message}>{errors.transferReason.name}</div>}
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA UGX::Mobile*/}
            {
                (paymentTypeByRegionForm === "UGX::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["ug"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * MOBILE PROVIDER
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"mobileProvider"}
                                    name={"mobileProvider"}
                                    touched={touched.mobileProvider}
                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                    items={mobileProvidersUganda}
                                />
                                {errors.mobileProvider && touched.mobileProvider &&
                                    <div
                                        className={s.error_message}>{errors.mobileProvider.name}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>

                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA EUR::Bank*/}
            {
                (paymentTypeByRegionForm === "EUR::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IBAN
                                </div>
                                <MyInput id={"recipientsIban"}
                                         name={"recipientsIban"}
                                         touched={touched.recipientsIban}
                                         value={values.recipientsIban}
                                         onChange={handleChange}
                                         isError={errors.recipientsIban}
                                />
                                {errors.recipientsIban && touched.recipientsIban &&
                                    <div
                                        className={s.error_message}>{errors.recipientsIban}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK NAME
                                </div>
                                <MyInput id={"recipientsBank"}
                                         name={"recipientsBank"}
                                         touched={touched.recipientsBank}
                                         value={values.recipientsBank}
                                         onChange={handleChange}
                                         isError={errors.recipientsBank}
                                />
                                {errors.recipientsBank && touched.recipientsBank &&
                                    <div
                                        className={s.error_message}>{errors.recipientsBank}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BIC CODE
                                </div>
                                <MyInput id={"recipientsSwiftBic"}
                                         name={"recipientsSwiftBic"}
                                         touched={touched.recipientsSwiftBic}
                                         value={values.recipientsSwiftBic}
                                         onChange={handleChange}
                                         isError={errors.recipientsSwiftBic}
                                />
                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                    <div
                                        className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                            </div>

                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA GBP::Bank*/}
            {
                (paymentTypeByRegionForm === "GBP::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IBAN
                                </div>
                                <MyInput id={"recipientsIban"}
                                         name={"recipientsIban"}
                                         touched={touched.recipientsIban}
                                         value={values.recipientsIban}
                                         onChange={handleChange}
                                         isError={errors.recipientsIban}
                                />
                                {errors.recipientsIban && touched.recipientsIban &&
                                    <div
                                        className={s.error_message}>{errors.recipientsIban}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK NAME
                                </div>
                                <MyInput id={"recipientsBank"}
                                         name={"recipientsBank"}
                                         touched={touched.recipientsBank}
                                         value={values.recipientsBank}
                                         onChange={handleChange}
                                         isError={errors.recipientsBank}
                                />
                                {errors.recipientsBank && touched.recipientsBank &&
                                    <div
                                        className={s.error_message}>{errors.recipientsBank}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BIC CODE
                                </div>
                                <MyInput id={"recipientsSwiftBic"}
                                         name={"recipientsSwiftBic"}
                                         touched={touched.recipientsSwiftBic}
                                         value={values.recipientsSwiftBic}
                                         onChange={handleChange}
                                         isError={errors.recipientsSwiftBic}
                                />
                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                    <div
                                        className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                            </div>

                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA USD::Bank*/}
            {
                (paymentTypeByRegionForm === "USD::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>

                        </div>

                        {/* <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK ACCOUNT
                                </div>
                                <MyInput id={"bankAccount"}
                                         name={"bankAccount"}
                                         touched={touched.bankAccount}
                                         value={values.bankAccount}
                                         onChange={handleChange}
                                         isError={errors.bankAccount}
                                />
                                {errors.bankAccount && touched.bankAccount &&
                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                            </div>
                        </div> */}
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    STREET
                                </div>
                                <MyInput id={"street"}
                                         name={"street"}
                                         touched={touched.street}
                                         value={values.street}
                                         onChange={handleChange}
                                         isError={errors.street}
                                />
                                {errors.street && touched.street &&
                                    <div className={s.error_message}>{errors.street}</div>}
                            </div>
                        </div>
                        
                    </div>


                    <div className={s.row}>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IBAN
                                </div>
                                <MyInput id={"recipientsIban"}
                                         name={"recipientsIban"}
                                         touched={touched.recipientsIban}
                                         value={values.recipientsIban}
                                         onChange={handleChange}
                                         isError={errors.recipientsIban}
                                         placeholder={"AA123 12345 123456789012 12"}
                                />
                                {errors.recipientsIban && touched.recipientsIban &&
                                    <div
                                        className={s.error_message}>{errors.recipientsIban}</div>}
                            </div>
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * COUNTRY
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"recipientCountry"}
                                    name={"recipientCountry"}
                                    touched={touched.recipientCountry}
                                    isError={errors.recipientCountry && errors.recipientCountry.name}
                                    items={egyptUsdBankCountries}
                                />
                                {errors.recipientCountry && touched.recipientCountry &&
                                    <div
                                        className={s.error_message}>{errors.recipientCountry.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}></div>

                    </div>

                    {/* <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK ACCOUNT TYPE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"bankAccountType"}
                                    name={"bankAccountType"}
                                    touched={touched.bankAccountType}
                                    isError={errors.bankAccountType && errors.bankAccountType.name}
                                    items={bankAccountTypesUS}
                                />
                                {errors.bankAccountType && touched.bankAccountType &&
                                    <div
                                        className={s.error_message}>{errors.bankAccountType.name}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK NAME
                                </div>
                                <MyInput id={"recipientsBank"}
                                         name={"recipientsBank"}
                                         touched={touched.recipientsBank}
                                         value={values.recipientsBank}
                                         onChange={handleChange}
                                         isError={errors.recipientsBank}
                                />
                                {errors.recipientsBank && touched.recipientsBank &&
                                    <div
                                        className={s.error_message}>{errors.recipientsBank}</div>}
                            </div>

                        </div>
                    </div> */}


                    {/* <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * ROUTING NUMBER
                                </div>
                                <MyInput id={"routingNumber"}
                                         name={"routingNumber"}
                                         touched={touched.routingNumber}
                                         value={values.routingNumber}
                                         onChange={handleChange}
                                         isError={errors.routingNumber}
                                         placeholder={"9 digits"}
                                />
                                {errors.routingNumber && touched.routingNumber &&
                                    <div
                                        className={s.error_message}>{errors.routingNumber}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * SWIFT CODE
                                </div>
                                <MyInput id={"recipientsSwiftBic"}
                                         name={"recipientsSwiftBic"}
                                         touched={touched.recipientsSwiftBic}
                                         value={values.recipientsSwiftBic}
                                         onChange={handleChange}
                                         isError={errors.recipientsSwiftBic}
                                />
                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                    <div
                                        className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                            </div>

                        </div>
                    </div> */}

                </div>
            }

            {/*PAN AFRICA MAD::Cash*/}
            {
                (paymentTypeByRegionForm === "MAD::Cash" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["ma"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTITY CARD TYPE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"identityCardType"}
                                    name={"identityCardType"}
                                    touched={touched.identityCardType}
                                    isError={errors.identityCardType && errors.identityCardType.name}
                                    items={idTypesMorocco}
                                />
                                {errors.identityCardType && touched.identityCardType &&
                                    <div
                                        className={s.error_message}>{errors.identityCardType.name}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTITY CARD ID
                                </div>
                                <MyInput id={"identityCardId"}
                                         name={"identityCardId"}
                                         touched={touched.identityCardId}
                                         value={values.identityCardId}
                                         onChange={handleChange}
                                         isError={errors.identityCardId}
                                />
                                {errors.identityCardId && touched.identityCardId &&
                                    <div
                                        className={s.error_message}>{errors.identityCardId}</div>}
                            </div>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA XOF::Cash*/}
            {
                (paymentTypeByRegionForm === "XOF::Cash" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * COUNTRY
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"recipientCountry"}
                                    name={"recipientCountry"}
                                    touched={touched.recipientCountry}
                                    isError={errors.recipientCountry && errors.recipientCountry.name}
                                    items={xofCashCountries}
                                />
                                {errors.recipientCountry && touched.recipientCountry &&
                                    <div
                                        className={s.error_message}>{errors.recipientCountry.name}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["sn"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>

                        </div>

                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * CASH PROVIDER
                                </div>
                                <MyInput id={"cashProvider"}
                                         name={"cashProvider"}
                                         touched={touched.cashProvider}
                                         value={"wizall"}
                                         onChange={handleChange}
                                         isError={errors.cashProvider}
                                />
                                {errors.cashProvider && touched.cashProvider &&
                                    <div
                                        className={s.error_message}>{errors.cashProvider}</div>}
                            </div>

                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA XOF::Mobile*/}
            {
                (paymentTypeByRegionForm === "XOF::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * COUNTRY
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"recipientCountry"}
                                name={"recipientCountry"}
                                touched={touched.recipientCountry}
                                isError={errors.recipientCountry && errors.recipientCountry.name}
                                items={xofMobileCountries}
                            />
                            {errors.recipientCountry && touched.recipientCountry &&
                                <div
                                    className={s.error_message}>{errors.recipientCountry.name}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * MOBILE PROVIDER
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"mobileProvider"}
                                name={"mobileProvider"}
                                touched={touched.mobileProvider}
                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                items={getMobileProvider(values.recipientCountry?.name)}
                            />
                            {errors.mobileProvider && touched.mobileProvider &&
                                <div
                                    className={s.error_message}>{errors.mobileProvider.name}</div>}
                        </div>
                    </div>

                    <div className={s.row}>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    recipientCountry={values.recipientCountry}
                                    onlyCountries={[getPhoneCode('XOF', values.recipientCountry?.name).co]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>

                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>

                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA XOF::Bank*/}
            {
                (paymentTypeByRegionForm === "XOF::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IBAN
                                </div>
                                <MyInput id={"recipientsIban"}
                                         name={"recipientsIban"}
                                         touched={touched.recipientsIban}
                                         value={values.recipientsIban}
                                         onChange={handleChange}
                                         isError={errors.recipientsIban}
                                         placeholder={"AA123 12345 123456789012 12"}
                                />
                                {errors.recipientsIban && touched.recipientsIban &&
                                    <div
                                        className={s.error_message}>{errors.recipientsIban}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA XAF::Bank*/}
            {
                (paymentTypeByRegionForm === "XAF::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * LAST NAME
                                </div>
                                <MyInput id={"lastName"}
                                         name={"lastName"}
                                         touched={touched.lastName}
                                         value={values.lastName}
                                         onChange={handleChange}
                                         isError={errors.lastName}
                                />
                                {errors.lastName && touched.lastName &&
                                    <div className={s.error_message}>{errors.lastName}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IBAN
                                </div>
                                <MyInput id={"recipientsIban"}
                                         name={"recipientsIban"}
                                         touched={touched.recipientsIban}
                                         value={values.recipientsIban}
                                         onChange={handleChange}
                                         isError={errors.recipientsIban}
                                         placeholder={"AA123 12345 123456789012 12"}
                                />
                                {errors.recipientsIban && touched.recipientsIban &&
                                    <div
                                        className={s.error_message}>{errors.recipientsIban}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA XAF::Mobile*/}
            {
                (paymentTypeByRegionForm === "XAF::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * MOBILE PROVIDER
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"mobileProvider"}
                                    name={"mobileProvider"}
                                    touched={touched.mobileProvider}
                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                    items={mobileProvidersXAF}
                                />
                                {errors.mobileProvider && touched.mobileProvider &&
                                    <div
                                        className={s.error_message}>{errors.mobileProvider.name}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["cm"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                    <div className={s.input_block}>
                            <div className={s.input_label}>
                                * COUNTRY
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"recipientCountry"}
                                name={"recipientCountry"}
                                touched={touched.recipientCountry}
                                isError={errors.recipientCountry && errors.recipientCountry.name}
                                items={xafMobileCountries}
                            />
                            {errors.recipientCountry && touched.recipientCountry &&
                                <div
                                    className={s.error_message}>{errors.recipientCountry.name}</div>}
                        </div>
                        <div className={s.input_block}></div>
                    </div>
                </div>
            }

            {/*PAN AFRICA GNF::Mobile*/}
            {
                (paymentTypeByRegionForm === "GNF::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * MOBILE PROVIDER
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"mobileProvider"}
                                    name={"mobileProvider"}
                                    touched={touched.mobileProvider}
                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                    items={mobileProvidersGuinea}
                                />
                                {errors.mobileProvider && touched.mobileProvider &&
                                    <div
                                        className={s.error_message}>{errors.mobileProvider.name}</div>}
                            </div>
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * Phone number
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["gn"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA ZAR::Bank*/}
            {
                (paymentTypeByRegionForm === "ZAR::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    STREET
                                </div>
                                <MyInput id={"street"}
                                         name={"street"}
                                         touched={touched.street}
                                         value={values.street}
                                         onChange={handleChange}
                                         isError={errors.street}
                                />
                                {errors.street && touched.street &&
                                    <div className={s.error_message}>{errors.street}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                POSTAL CODE
                            </div>
                            <MyInput id={"postalCode"}
                                     name={"postalCode"}
                                     touched={touched.postalCode}
                                     value={values.postalCode}
                                     onChange={handleChange}
                                     isError={errors.postalCode}
                            />
                            {errors.postalCode && touched.postalCode &&
                                <div className={s.error_message}>{errors.postalCode}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    CITY
                                </div>
                                <MyInput id={"city"}
                                         name={"city"}
                                         touched={touched.city}
                                         value={values.city}
                                         onChange={handleChange}
                                         isError={errors.city}
                                />
                                {errors.city && touched.city &&
                                    <div className={s.error_message}>{errors.city}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * EMAIL
                            </div>
                            <MyInput id={"email"}
                                     name={"email"}
                                     touched={touched.email}
                                     value={values.email}
                                     onChange={handleChange}
                                     isError={errors.email}
                            />
                            {errors.email && touched.email &&
                                <div className={s.error_message}>{errors.email}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK CODE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"bankCode"}
                                    name={"bankCode"}
                                    touched={touched.bankCode}
                                    isError={errors.bankCode && errors.bankCode.name}
                                    items={bankCodeListZARBank}
                                />
                                {errors.bankCode && touched.bankCode &&
                                    <div
                                        className={s.error_message}>{errors.bankCode.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"recipientsAccountNumber"}
                                     name={"recipientsAccountNumber"}
                                     touched={touched.recipientsAccountNumber}
                                     value={values.recipientsAccountNumber}
                                     onChange={handleChange}
                                     isError={errors.recipientsAccountNumber}
                            />
                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                <div
                                    className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * PHONE NUMBER
                                </div>
                                <PhoneCodesInput
                                    id='phoneNumber'
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    selectable = {false}
                                    onlyCountries={["za"]}
                                />
                                {errors.phoneNumber &&
                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                TRANSFER REASON
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"transferReason"}
                                name={"transferReason"}
                                touched={touched.transferReason}
                                isError={errors.transferReason && errors.transferReason.name}
                                items={transferReasons}
                            />
                            {errors.transferReason && touched.transferReason &&
                                <div
                                    className={s.error_message}>{errors.transferReason.name}</div>}
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA BWP::Bank*/}
            {
                (paymentTypeByRegionForm === "BWP::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK ACCOUNT
                                </div>
                                <MyInput id={"recipientsAccountNumber"}
                                         name={"recipientsAccountNumber"}
                                         touched={touched.recipientsAccountNumber}
                                         value={values.recipientsAccountNumber}
                                         onChange={handleChange}
                                         isError={errors.recipientsAccountNumber}
                                />
                                {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                    <div
                                        className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK NAME
                            </div>
                            <MyInput id={"recipientsBank"}
                                     name={"recipientsBank"}
                                     touched={touched.recipientsBank}
                                     value={values.recipientsBank}
                                     onChange={handleChange}
                                     isError={errors.recipientsBank}
                            />
                            {errors.recipientsBank && touched.recipientsBank &&
                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * BRANCH CODE
                                    </div>
                                    <CustomDropdownForAzaTransactions
                                        id={"branchCode"}
                                        name={"branchCode"}
                                        touched={touched.branchCode}
                                        isError={errors.branchCode && errors.branchCode.name}
                                        items={branchCodeListBWPBank}
                                    />
                                    {errors.branchCode && touched.branchCode &&
                                        <div
                                            className={s.error_message}>{errors.branchCode.name}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/*PAN AFRICA PIX Payments*/}
            {
                (paymentTypeByRegionForm === "PIX Payments" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * CITY
                                </div>
                                <MyInput id={"city"}
                                         name={"city"}
                                         touched={touched.city}
                                         value={values.city}
                                         onChange={handleChange}
                                         isError={errors.city}
                                />
                                {errors.city && touched.city &&
                                    <div className={s.error_message}>{errors.city}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * POSTAL CODE
                            </div>
                            <MyInput id={"postalCode"}
                                     name={"postalCode"}
                                     touched={touched.postalCode}
                                     value={values.postalCode}
                                     onChange={handleChange}
                                     isError={errors.postalCode}
                            />
                            {errors.postalCode && touched.postalCode &&
                                <div className={s.error_message}>{errors.postalCode}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * PIX KEY TYPE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"pixKeyType"}
                                    name={"pixKeyType"}
                                    touched={touched.pixKeyType}
                                    isError={errors.pixKeyType && errors.pixKeyType.name}
                                    items={pixKeyTypesBrazil}
                                />
                                {errors.pixKeyType && touched.pixKeyType &&
                                    <div
                                        className={s.error_message}>{errors.pixKeyType.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * PIX KEY VALUE
                            </div>
                            <MyInput id={"pixKeyValue"}
                                     name={"pixKeyValue"}
                                     touched={touched.pixKeyValue}
                                     value={values.pixKeyValue}
                                     onChange={handleChange}
                                     isError={errors.pixKeyValue}
                            />
                            {errors.pixKeyValue && touched.pixKeyValue &&
                                <div className={s.error_message}>{errors.pixKeyValue}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTITY CARD ID
                                </div>
                                <MyInput id={"identityCardId"}
                                         name={"identityCardId"}
                                         touched={touched.identityCardId}
                                         value={values.identityCardId}
                                         onChange={handleChange}
                                         isError={errors.identityCardId}
                                />
                                {errors.identityCardId && touched.identityCardId &&
                                    <div
                                        className={s.error_message}>{errors.identityCardId}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * TRANSFER REASON
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"transferReason"}
                                name={"transferReason"}
                                touched={touched.transferReason}
                                isError={errors.transferReason && errors.transferReason.name}
                                items={transferReasons}
                            />
                            {errors.transferReason && touched.transferReason &&
                                <div
                                    className={s.error_message}>{errors.transferReason.name}</div>}
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA TED Payments*/}
            {
                (paymentTypeByRegionForm === "TED Payments" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * CITY
                                </div>
                                <MyInput id={"city"}
                                         name={"city"}
                                         touched={touched.city}
                                         value={values.city}
                                         onChange={handleChange}
                                         isError={errors.city}
                                />
                                {errors.city && touched.city &&
                                    <div className={s.error_message}>{errors.city}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * POSTAL CODE
                            </div>
                            <MyInput id={"postalCode"}
                                     name={"postalCode"}
                                     touched={touched.postalCode}
                                     value={values.postalCode}
                                     onChange={handleChange}
                                     isError={errors.postalCode}
                            />
                            {errors.postalCode && touched.postalCode &&
                                <div className={s.error_message}>{errors.postalCode}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK CODE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"bankCode"}
                                    name={"bankCode"}
                                    touched={touched.bankCode}
                                    isError={errors.bankCode && errors.bankCode.name}
                                    items={bankCodeListTEDPayments}
                                />
                                {errors.bankCode && touched.bankCode &&
                                    <div
                                        className={s.error_message}>{errors.bankCode.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BRANCH CODE
                            </div>
                            <MyInput id={"branchCodeTED"}
                                     name={"branchCodeTED"}
                                     touched={touched.branchCodeTED}
                                     value={values.branchCodeTED}
                                     onChange={handleChange}
                                     isError={errors.branchCodeTED}
                            />
                            {errors.branchCodeTED && touched.branchCodeTED &&
                                <div className={s.error_message}>{errors.branchCodeTED}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK ACCOUNT
                                </div>
                                <MyInput id={"bankAccount"}
                                         name={"bankAccount"}
                                         touched={touched.bankAccount}
                                         value={values.bankAccount}
                                         onChange={handleChange}
                                         isError={errors.bankAccount}
                                />
                                {errors.bankAccount && touched.bankAccount &&
                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT TYPE
                                <Popover
                                    content={"10 for savings, 20 for current, 30 for payment, 40 " +
                                        "for salary"}
                                    color={'#EEEFEF'}
                                    overlayInnerStyle={{
                                        fontFamily: 'Inter',
                                        width: '280px',
                                        padding: '13px 16px'
                                    }}
                                >
                                    <img src={info} alt=""/>
                                </Popover>
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"bankAccountType"}
                                name={"bankAccountType"}
                                touched={touched.bankAccountType}
                                isError={errors.bankAccountType && errors.bankAccountType.name}
                                items={bankAccountTypesTED}
                            />
                            {errors.bankAccountType && touched.bankAccountType &&
                                <div
                                    className={s.error_message}>{errors.bankAccountType.name}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * IDENTITY CARD ID
                            </div>
                            <MyInput id={"identityCardId"}
                                     name={"identityCardId"}
                                     touched={touched.identityCardId}
                                     value={values.identityCardId}
                                     onChange={handleChange}
                                     isError={errors.identityCardId}
                            />
                            {errors.identityCardId && touched.identityCardId &&
                                <div className={s.error_message}>{errors.identityCardId}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * TRANSFER REASON
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"transferReason"}
                                name={"transferReason"}
                                touched={touched.transferReason}
                                isError={errors.transferReason && errors.transferReason.name}
                                items={transferReasons}
                            />
                            {errors.transferReason && touched.transferReason &&
                                <div
                                    className={s.error_message}>{errors.transferReason.name}</div>}
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA ZMW::Bank*/}
            {
                (paymentTypeByRegionForm === "ZMW::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK ACCOUNT
                                </div>
                                <MyInput id={"bankAccount"}
                                         name={"bankAccount"}
                                         touched={touched.bankAccount}
                                         value={values.bankAccount}
                                         onChange={handleChange}
                                         isError={errors.bankAccount}
                                />
                                {errors.bankAccount && touched.bankAccount &&
                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BRANCH CODE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"branchCode"}
                                name={"branchCode"}
                                touched={touched.branchCode}
                                isError={errors.branchCode && errors.branchCode.name}
                                items={branchCodeListZMWBank}
                            />
                            {errors.branchCode && touched.branchCode &&
                                <div className={s.error_message}>{errors.branchCode.name}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA KES::Bank*/}
            {
                (paymentTypeByRegionForm === "KES::Bank" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Sender’s Details
                    </div>
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * IDENTIFICATION TYPE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"senderIdType"}
                                name={"senderIdType"}
                                touched={touched.senderIdType}
                                isError={errors.senderIdType && errors.senderIdType.name}
                                items={idTypesKenya}
                            />
                            {errors.senderIdType && touched.senderIdType &&
                                <div
                                    className={s.error_message}>{errors.senderIdType.name}</div>}

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTIFICATION NUMBER
                                </div>
                                <MyInput id={"senderIdNumber"}
                                         name={"senderIdNumber"}
                                         touched={touched.senderIdNumber}
                                         value={values.senderIdNumber}
                                         onChange={handleChange}
                                         isError={errors.senderIdNumber}
                                />
                                {errors.senderIdNumber && touched.senderIdNumber &&
                                    <div
                                        className={s.error_message}>{errors.senderIdNumber}</div>}

                            </div>
                        </div>
                    </div>

                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * STREET
                                </div>
                                <MyInput id={"street"}
                                         name={"street"}
                                         touched={touched.street}
                                         value={values.street}
                                         onChange={handleChange}
                                         isError={errors.street}
                                />
                                {errors.street && touched.street &&
                                    <div className={s.error_message}>{errors.street}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                CITY
                            </div>
                            <MyInput id={"city"}
                                     name={"city"}
                                     touched={touched.city}
                                     value={values.city}
                                     onChange={handleChange}
                                     isError={errors.city}
                            />
                            {errors.city && touched.city &&
                                <div className={s.error_message}>{errors.city}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK CODE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"bankCode"}
                                    name={"bankCode"}
                                    touched={touched.bankCode}
                                    isError={errors.bankCode && errors.bankCode.name}
                                    items={bankCodeListKESBank}
                                />
                                {errors.bankCode && touched.bankCode &&
                                    <div
                                        className={s.error_message}>{errors.bankCode.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"bankAccount"}
                                     name={"bankAccount"}
                                     touched={touched.bankAccount}
                                     value={values.bankAccount}
                                     onChange={handleChange}
                                     isError={errors.bankAccount}
                            />
                            {errors.bankAccount && touched.bankAccount &&
                                <div className={s.error_message}>{errors.bankAccount}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    IDENTITY CARD TYPE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"identityCardType"}
                                    name={"identityCardType"}
                                    touched={touched.identityCardType}
                                    isError={errors.identityCardType && errors.identityCardType.name}
                                    items={idTypesKenya}
                                />
                                {errors.identityCardType && touched.identityCardType &&
                                    <div
                                        className={s.error_message}>{errors.identityCardType.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                IDENTITY CARD ID
                            </div>
                            <MyInput id={"identityCardId"}
                                     name={"identityCardId"}
                                     touched={touched.identityCardId}
                                     value={values.identityCardId}
                                     onChange={handleChange}
                                     isError={errors.identityCardId}
                            />
                            {errors.identityCardId && touched.identityCardId &&
                                <div className={s.error_message}>{errors.identityCardId}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                RELATIONSHIP TO SENDER
                            </div>
                            <MyInput id={"relationshipToSender"}
                                     name={"relationshipToSender"}
                                     touched={touched.relationshipToSender}
                                     value={values.relationshipToSender}
                                     onChange={handleChange}
                                     isError={errors.relationshipToSender}
                            />
                            {errors.relationshipToSender && touched.relationshipToSender &&
                                <div
                                    className={s.error_message}>{errors.relationshipToSender}</div>}
                        </div>

                        <div className={s.input_block}>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA KES::Mobile*/}
            {
                (paymentTypeByRegionForm === "KES::Mobile" ) &&
                <div className={s.block}>
                    <div className={s.title}>
                        Sender’s Details
                    </div>
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * IDENTIFICATION TYPE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"senderIdType"}
                                name={"senderIdType"}
                                touched={touched.senderIdType}
                                isError={errors.senderIdType && errors.senderIdType.name}
                                items={idTypesKenya}
                            />
                            {errors.senderIdType && touched.senderIdType &&
                                <div
                                    className={s.error_message}>{errors.senderIdType.name}</div>}

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTIFICATION NUMBER
                                </div>
                                <MyInput id={"senderIdNumber"}
                                         name={"senderIdNumber"}
                                         touched={touched.senderIdNumber}
                                         value={values.senderIdNumber}
                                         onChange={handleChange}
                                         isError={errors.senderIdNumber}
                                />
                                {errors.senderIdNumber && touched.senderIdNumber &&
                                    <div
                                        className={s.error_message}>{errors.senderIdNumber}</div>}

                            </div>
                        </div>
                    </div>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * STREET
                            </div>
                            <MyInput id={"street"}
                                     name={"street"}
                                     touched={touched.street}
                                     value={values.street}
                                     onChange={handleChange}
                                     isError={errors.street}
                            />
                            {errors.street && touched.street &&
                                <div className={s.error_message}>{errors.street}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    CITY
                                </div>
                                <MyInput id={"city"}
                                         name={"city"}
                                         touched={touched.city}
                                         value={values.city}
                                         onChange={handleChange}
                                         isError={errors.city}
                                />
                                {errors.city && touched.city &&
                                    <div className={s.error_message}>{errors.city}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * Phone number
                            </div>
                            <PhoneCodesInput
                                id='phoneNumber'
                                name='phoneNumber'
                                onChange={handleChange}
                                value={values.phoneNumber}
                                error={errors.phoneNumber}
                                touched={touched.phoneNumber}
                                selectable = {false}
                                onlyCountries={["ke"]}
                            />
                            {errors.phoneNumber &&
                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTITY CARD TYPE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"identityCardType"}
                                    name={"identityCardType"}
                                    touched={touched.identityCardType}
                                    isError={errors.identityCardType && errors.identityCardType.name}
                                    items={idTypesKenya}
                                />
                                {errors.identityCardType && touched.identityCardType &&
                                    <div
                                        className={s.error_message}>{errors.identityCardType.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * IDENTITY CARD ID
                            </div>
                            <MyInput id={"identityCardId"}
                                     name={"identityCardId"}
                                     touched={touched.identityCardId}
                                     value={values.identityCardId}
                                     onChange={handleChange}
                                     isError={errors.identityCardId}
                            />
                            {errors.identityCardId && touched.identityCardId &&
                                <div className={s.error_message}>{errors.identityCardId}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * MOBILE PROVIDER
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"mobileProvider"}
                                name={"mobileProvider"}
                                touched={touched.mobileProvider}
                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                items={mobileProvidersKenya}
                            />
                            {errors.mobileProvider && touched.mobileProvider &&
                                <div
                                    className={s.error_message}>{errors.mobileProvider.name}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    RELATIONSHIP TO SENDER
                                </div>
                                <MyInput id={"relationshipToSender"}
                                         name={"relationshipToSender"}
                                         touched={touched.relationshipToSender}
                                         value={values.relationshipToSender}
                                         onChange={handleChange}
                                         isError={errors.relationshipToSender}
                                />
                                {errors.relationshipToSender && touched.relationshipToSender &&
                                    <div
                                        className={s.error_message}>{errors.relationshipToSender}</div>}
                            </div>
                        </div>
                    </div>

                </div>
            }

            {/*PAN AFRICA EGP::Bank*/}
            {
                (paymentTypeByRegionForm === "EGP::Bank" ) &&
                <div className={s.block}>

                    <div className={s.title}>
                        Sender’s Details
                    </div>
                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * IDENTIFICATION TYPE
                            </div>
                            <CustomDropdownForAzaTransactions
                                id={"senderIdType"}
                                name={"senderIdType"}
                                touched={touched.senderIdType}
                                isError={errors.senderIdType && errors.senderIdType.name}
                                items={idTypesKenya}
                            />
                            {errors.senderIdType && touched.senderIdType &&
                                <div
                                    className={s.error_message}>{errors.senderIdType.name}</div>}

                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * IDENTIFICATION NUMBER
                                </div>
                                <MyInput id={"senderIdNumber"}
                                         name={"senderIdNumber"}
                                         touched={touched.senderIdNumber}
                                         value={values.senderIdNumber}
                                         onChange={handleChange}
                                         isError={errors.senderIdNumber}
                                />
                                {errors.senderIdNumber && touched.senderIdNumber &&
                                    <div
                                        className={s.error_message}>{errors.senderIdNumber}</div>}

                            </div>
                        </div>
                    </div>
                    <div className={s.title}>
                        Recipient’s Details
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * FIRST NAME
                            </div>
                            <MyInput id={"firstName"}
                                     name={"firstName"}
                                     touched={touched.firstName}
                                     value={values.firstName}
                                     onChange={handleChange}
                                     isError={errors.firstName}
                            />
                            {errors.firstName && touched.firstName &&
                                <div className={s.error_message}>{errors.firstName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                MIDDLE NAME
                            </div>
                            <MyInput id={"middleName"}
                                     name={"middleName"}
                                     touched={touched.middleName}
                                     value={values.middleName}
                                     onChange={handleChange}
                                     isError={errors.middleName}
                            />
                            {errors.middleName && touched.middleName &&
                                <div className={s.error_message}>{errors.middleName}</div>}
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * LAST NAME
                            </div>
                            <MyInput id={"lastName"}
                                     name={"lastName"}
                                     touched={touched.lastName}
                                     value={values.lastName}
                                     onChange={handleChange}
                                     isError={errors.lastName}
                            />
                            {errors.lastName && touched.lastName &&
                                <div className={s.error_message}>{errors.lastName}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * STREET
                                </div>
                                <MyInput id={"street"}
                                         name={"street"}
                                         touched={touched.street}
                                         value={values.street}
                                         onChange={handleChange}
                                         isError={errors.street}
                                />
                                {errors.street && touched.street &&
                                    <div className={s.error_message}>{errors.street}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * PHONE NUMBER
                            </div>
                            <PhoneCodesInput
                                id='phoneNumber'
                                name='phoneNumber'
                                onChange={handleChange}
                                value={values.phoneNumber}
                                error={errors.phoneNumber}
                                touched={touched.phoneNumber}
                                selectable = {false}
                                onlyCountries={["eg"]}
                            />
                            {errors.phoneNumber &&
                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * BANK CODE
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"bankCode"}
                                    name={"bankCode"}
                                    touched={touched.bankCode}
                                    isError={errors.bankCode && errors.bankCode.name}
                                    items={bankCodeListEGPBank}
                                />
                                {errors.bankCode && touched.bankCode &&
                                    <div
                                        className={s.error_message}>{errors.bankCode.name}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={s.row}>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                * BANK ACCOUNT
                            </div>
                            <MyInput id={"bankAccount"}
                                     name={"bankAccount"}
                                     touched={touched.bankAccount}
                                     value={values.bankAccount}
                                     onChange={handleChange}
                                     isError={errors.bankAccount}
                            />
                            {errors.bankAccount && touched.bankAccount &&
                                <div className={s.error_message}>{errors.bankAccount}</div>}
                        </div>

                        <div className={s.input_block}>
                            <div className={s.input_block}>
                                <div className={s.input_label}>
                                    * TRANSFER REASON
                                </div>
                                <CustomDropdownForAzaTransactions
                                    id={"transferReason"}
                                    name={"transferReason"}
                                    touched={touched.transferReason}
                                    isError={errors.transferReason && errors.transferReason.name}
                                    items={transferReasons}
                                />
                                {errors.transferReason && touched.transferReason &&
                                    <div
                                        className={s.error_message}>{errors.transferReason.name}</div>}
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    );
};

export default NigeriaUsdBank;
