import React from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {transferReasons} from "../../../constants/StaticData";

interface FormValues {
    name?: string;
    recipientsIban?: string;
    transferReason?: {
        name?: string;
        value?: string;
    };
}

interface FormErrors {
    name?: string;
    recipientsIban?: string;
    transferReason?: {
        name?: string;
        value?: string;
    };
}

interface FormTouched {
    name?: boolean;
    recipientsIban?: boolean;
    transferReason?: {
        name?: boolean;
        value?: boolean;
    };
}

interface FormikProps {
    values: FormValues;
    errors: FormErrors;
    touched: FormTouched;
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const XafBank: React.FC<FormikProps> = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange
                                        }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput id={"name"}
                             name={"name"}
                             touched={touched.name}
                             value={values.name}
                             onChange={handleChange}
                             isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

            </div>

            <div className={s.row}>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * IBAN
                        </div>
                        <MyInput id={"recipientsIban"}
                                 name={"recipientsIban"}
                                 touched={touched.recipientsIban}
                                 value={values.recipientsIban}
                                 onChange={handleChange}
                                 isError={errors.recipientsIban}
                                 placeholder={"AA123 12345 123456789012 12"}
                        />
                        {errors.recipientsIban && touched.recipientsIban &&
                            <div className={s.error_message}>{errors.recipientsIban}</div>}
                    </div>
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * TRANSFER REASON
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"transferReason"}
                            name={"transferReason"}
                            touched={touched.transferReason}
                            isError={errors?.transferReason?.name}
                            items={transferReasons}
                        />
                        {errors.transferReason && touched.transferReason &&
                            <div
                                className={s.error_message}>{errors?.transferReason?.name}</div>}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default XafBank;
