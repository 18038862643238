import React from 'react';
import s from "../AccountTopUp.module.css";
import {Collapse} from "antd";
import minus from "../../../../../assets/images/publicPages/faq/minus.svg";
import plus from "../../../../../assets/images/publicPages/faq/plus.svg";

type PropsType = {
    list?: any
}

const {Panel} = Collapse;

const CorrespondentAccount = (props: PropsType) => {

    const indexOfMainItem = props?.list.findIndex((el:any) => el.isMain)

    return (
        <div>
            <div className={s.infoWrapper}>
                {
                    props.list?.map((item: any, index: number) => item.status === 2 &&
                        <Collapse
                            key={index}
                            className={s.my_collapse}
                            expandIconPosition={"end"}
                            bordered={false}
                            defaultActiveKey={indexOfMainItem}
                            expandIcon={({isActive}) => isActive ?
                                <img className={s.icon_minus} src={minus} alt=""/> :
                                <img src={plus} className={s.icon_plus} alt=""/>}
                            style={{
                                background: "transparent",
                                alignItems: 'center',
                            }}
                        >
                            <Panel header={item.bankName} key={index} className={s.panelStyle}>
                                <div className={s.collapse_text}>
                                    <div className={s.line}>
                                        <p className={s.field}>
                                            Beneficiary Name
                                        </p>
                                        <p className={s.info}>
                                            {item?.beneficiaryName}
                                        </p>
                                    </div>

                                    <div className={s.line}>
                                        <p className={s.field}>
                                            Beneficiary Address
                                        </p>
                                        <p className={s.info}>
                                            {item?.beneficiaryAddress}
                                        </p>
                                    </div>

                                    <div className={s.line}>
                                        <p className={s.field}>
                                            Beneficiary Account Number
                                        </p>
                                        <p className={s.info}>
                                            {item?.iban}
                                        </p>
                                    </div>

                                    <div className={s.line}>
                                        <p className={s.field}>
                                            Bank Name
                                        </p>
                                        <p className={s.info}>
                                            {item?.bankName}
                                        </p>
                                    </div>

                                    <div className={s.line}>
                                        <p className={s.field}>
                                            SWIFT
                                        </p>
                                        <p className={s.info}>
                                            {item?.swift}
                                        </p>
                                    </div>

                                    <div className={s.line}>
                                        <p className={s.field}>
                                            Bank Address
                                        </p>
                                        <p className={s.info}>
                                            {item?.bankAddress}
                                        </p>
                                    </div>

                                    <div className={s.line}>
                                        <p className={s.field}>
                                            Reference
                                        </p>
                                        <p className={s.info}>
                                            {item?.reference}
                                        </p>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                    )
                }
            </div>
        </div>
    );
};

export default CorrespondentAccount;
