import React, {Dispatch, SetStateAction, useState} from 'react';
import s from './SignIn.module.css'
import attention from '../../../assets/images/common/attention.svg'
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch} from "../../../hooks/redux";
import {useFormik} from "formik";
import {motion} from 'framer-motion';
import {NavLink} from "react-router-dom";
import {getAdditionalsThunk} from "../../../store/reducers/ActionCreators";
import MyInput from "../../../components/ui/MyInput/MyInput";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {brandConfig} from '../../../config/config';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type LoginDataType = {
    email: string;
    password: string;
    emailCode: string;
    twoFaCode: string;
    smsCode: string;
};

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>;
    setLoginData: Dispatch<SetStateAction<LoginDataType>>;
    setAdditionalSettings: Dispatch<SetStateAction<any>>;
    current?: number;
    dataForSend: LoginDataType;
};

const SignIn = (props: PropsType) => {
    const dispatch = useAppDispatch()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isAuthSuccess, setIsAuthSuccess] = useState(true)
    const [validateCaptcha, setValidateCaptcha] = useState(true)

    const formikLogin = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async values => {
            if (!executeRecaptcha) {
                console.log('reCAPTCHA is not ready');
                return;
            }
            dispatch(setDisabled(true))
            const recaptchaToken = await executeRecaptcha('login');
            const finalValues = { ...values, recaptchaToken };
            await dispatch(getAdditionalsThunk(finalValues))
                .then((res) => {
                    props.setAdditionalSettings(res.data)
                    props.setLoginData(
                        {...props.dataForSend,
                            email: values.email,
                            password: values.password,
                        }
                    )
                    props.setCurrent(1)
                }).catch((e) => {
                    if(e.response.data === "reCAPTCHA verification failed") {
                        setValidateCaptcha(false);
                    }
                    setIsAuthSuccess(false)
                })
            dispatch(setDisabled(false))
        }
    });

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className={s.sign_in_wrapper}>
                <form onSubmit={formikLogin.handleSubmit}>
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            Great to see you again!
                        </div>
                        {
                            (!isAuthSuccess && validateCaptcha) &&
                            <div className={s.error_block}>
                                <img src={attention} alt="" />
                                <div>
                                    Invalid email address or password
                                </div>
                            </div>
                        }
                        {
                            (!validateCaptcha) &&
                            <div className={s.error_block}>
                                <img src={attention} alt="" />
                                <div>
                                    Failed to validate reCaptcha
                                </div>
                            </div>
                        }
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Email address
                            </div>
                            <MyInput
                                id="email"
                                name="email"
                                value={formikLogin.values.email}
                                onChange={formikLogin.handleChange}
                            />
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                Password
                            </div>
                            <MyInput
                                id="password"
                                name="password"
                                password
                                value={formikLogin.values.password}
                                onChange={formikLogin.handleChange}
                            />
                        </div>
                        <div className={s.forgot} onClick={() => props.setCurrent(2)}>
                            Forgot password?
                        </div>
                        <div className={s.btn_block}>
                            <MyBtn
                                title={"Sign In"}
                                type={"submit"}
                            />
                        </div>
                        {brandConfig.companyComplianceEmailAddress &&
                            <div className={s.forget_credentials}>
                                If you forget all your credentials from your account, please contact our Support via&nbsp;
                                <a href={`mailto:${brandConfig.companyComplianceEmailAddress}`}>
                                    {brandConfig.companyComplianceEmailAddress}
                                </a>
                            </div>
                        }
                    </div>
                    <div className={s.dont_have_account}>
                        <div>
                            Don’t have an account?
                        </div>
                        <NavLink className={s.link} to={"/sign_up"}>Open Account</NavLink>
                    </div>
                </form>
            </div>
        </motion.div>
    );
};

export default SignIn;
