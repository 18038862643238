import React, {useState} from 'react';
import s from "./ToAnIberbancoStepTwo.module.css";
import {ReactComponent as Back} from "../../../../../assets/images/personalAccount/back.svg";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {motion} from "framer-motion";
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {SendTransferThunk} from "../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {PrepareDataForIberbancoTransaction} from "../../../../../helpers/PrepareDataForIberbancoTransaction";
import {setDisabled} from "../../../../../store/reducers/UserSlice";

const ToAnIberbancoStepTwo = (props: any) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)

    const sendTransferData = async () => {
        dispatch(setDisabled(true))
        const tempData = PrepareDataForIberbancoTransaction(props.transferData)

        dispatch(SendTransferThunk(token, await tempData))
            .then((res) => {
                if (res.data === 'Created') {
                    setIsSuccessPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })

    }

    const closePopupAndRedirect = () => {
        setIsSuccessPopupOpen(false)
        localStorage.removeItem('iberbanco-transfer-form')
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isModalOpen={isSuccessPopupOpen} setIsModalOpen={setIsSuccessPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_title}>
                        Success!
                    </div>
                    <div className={s.popup_subtitle}>
                        Your request has been sent.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn title={'Ok'} medium onClick={() => closePopupAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_title}>
                        Oops!
                    </div>
                    <div className={s.popup_subtitle}>
                        Something went wrong. Please try again.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.title}>
                Transfer Information
            </div>
            <div className={s.data_block}>
                <div className={s.row}>
                    <span className={s.row_name}>Payer account</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.accNumberFrom !== ''
                                ? props.transferData.accNumberFrom
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Transfer method</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.transferMethod !== ''
                                ? props.transferData.transferMethod
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Recipient’s account</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.phoneNumber && "+"
                        }
                        {
                            props.transferData.email !== '' && props.transferData.transferMethod === 'Email'
                                ? props.transferData.email
                                : <></>
                        }
                        {
                            props.transferData.accNumberTo !== ''
                                ? props.transferData.accNumberTo
                                : '-'
                        }

                        {
                            props.transferData.phoneNumber !== ''
                                ? props.transferData.phoneNumber
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Recipient’s email</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.email !== ''
                                ? props.transferData.email
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Payment date</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.paymentDate !== ''
                                ? props.transferData.paymentDate
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Amount</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.amount !== ''
                                ? `${props.transferData.amount} ${props.transferData.transferCurrency}`
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Reference</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.reference !== ''
                                ? props.transferData.reference
                                : '-'
                        }
                    </span>
                </div>

                <div className={s.row}>
                    <span className={s.row_name}>Template name</span>
                    <span className={s.row_value}>
                        {
                            props.transferData.templateName !== ''
                                ? props.transferData.templateName
                                : '-'
                        }
                    </span>
                </div>

                {
                    (props.transferData.invoice !== '' && props.transferData.invoice?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Invoice</span>
                        <span className={s.row_value}>
                            {props.transferData.invoice?.name}
                        </span>
                    </div>
                }

            </div>
            <div className={s.buttons_block}>
                <div className={s.button_wrapper}>
                    <TransparentButton
                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        large
                        title={"Back"}
                        onClick={() => props.setCurrent(0)}/>
                </div>

                <div className={s.button_wrapper}>
                    <MyBtn title={"Transfer"} onClick={() => sendTransferData()}/>
                </div>
            </div>
        </motion.div>
    );
};

export default ToAnIberbancoStepTwo;
