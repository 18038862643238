import React from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {bankCodeListGhanaGHSBank} from "../../../constants/StaticData";

interface FormikProps {
    values: {
        name?: string;
        bankAccount?: string;
        bankCode?: {
            name?: string,
            value?: string
        };
    };
    errors: {
        name?: string;
        bankAccount?: string;
        bankCode?: {
            name?: string,
            value?: string
        };
    };
    touched: {
        name?: boolean;
        bankAccount?: boolean;
        bankCode?: {
            name?: boolean,
            value?: boolean
        };
    };
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const GhsBank: React.FC<FormikProps> = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                        }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput id={"name"}
                             name={"name"}
                             touched={touched.name}
                             value={values.name}
                             onChange={handleChange}
                             isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK CODE
                    </div>
                    <CustomDropdownForAzaTransactions
                        id={"bankCode"}
                        name={"bankCode"}
                        touched={touched.bankCode}
                        isError={errors?.bankCode?.name}
                        items={bankCodeListGhanaGHSBank}
                    />
                    {errors?.bankCode?.name && touched?.bankCode?.name &&
                        <div className={s.error_message}>{errors.bankCode.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK ACCOUNT
                    </div>
                    <MyInput id={"bankAccount"}
                             name={"bankAccount"}
                             touched={touched.bankAccount}
                             value={values.bankAccount}
                             onChange={handleChange}
                             isError={errors.bankAccount}
                    />
                    {errors.bankAccount && touched.bankAccount &&
                        <div className={s.error_message}>{errors.bankAccount}</div>}
                </div>
            </div>
        </div>
    );
};

export default GhsBank;
