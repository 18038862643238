import React, {useState} from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions
    from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {
    bankCodeListZARBank,
    entityTypes,
    natureOfBusinessTypes,
    transferReasons
} from "../../../constants/StaticData";
import PhoneCodesInput from "../../ui/PhoneCodesInput/PhoneCodesInput";
import {getPhoneCode} from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";

interface FormikProps {
    values: any;
    errors: any;
    touched: any;
    handleChange: (e: React.ChangeEvent<any>) => void;

    recipientsBankCountry: any;
    updateParentCode?: (value: any) => any; // Function that accepts a string

}


const ZarBank: React.FC<FormikProps>  = ({
                     values,
                     errors,
                     touched,
                     handleChange,
                     recipientsBankCountry,
                     updateParentCode
                 }) => {


    const [innerCode, setInnerCode] = useState({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    });


    if (getPhoneCode(recipientsBankCountry, null) !== innerCode) {
        setInnerCode(getPhoneCode(recipientsBankCountry, null))
        updateParentCode && updateParentCode(getPhoneCode(recipientsBankCountry, null))

    }


    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput id={"name"}
                             name={"name"}
                             touched={touched.name}
                             value={values.name}
                             onChange={handleChange}
                             isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            STREET
                        </div>
                        <MyInput id={"street"}
                                 name={"street"}
                                 touched={touched.street}
                                 value={values.street}
                                 onChange={handleChange}
                                 isError={errors.street}
                        />
                        {errors.street && touched.street &&
                            <div className={s.error_message}>{errors.street}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        POSTAL CODE
                    </div>
                    <MyInput id={"postalCode"}
                             name={"postalCode"}
                             touched={touched.postalCode}
                             value={values.postalCode}
                             onChange={handleChange}
                             isError={errors.postalCode}
                    />
                    {errors.postalCode && touched.postalCode &&
                        <div className={s.error_message}>{errors.postalCode}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            CITY
                        </div>
                        <MyInput id={"city"}
                                 name={"city"}
                                 touched={touched.city}
                                 value={values.city}
                                 onChange={handleChange}
                                 isError={errors.city}
                        />
                        {errors.city && touched.city &&
                            <div className={s.error_message}>{errors.city}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * EMAIL
                    </div>
                    <MyInput id={"email"}
                             name={"email"}
                             touched={touched.email}
                             value={values.email}
                             onChange={handleChange}
                             isError={errors.email}
                    />
                    {errors.email && touched.email &&
                        <div className={s.error_message}>{errors.email}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * BANK CODE
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"bankCode"}
                            name={"bankCode"}
                            touched={touched.bankCode}
                            isError={errors?.bankCode?.name}
                            items={bankCodeListZARBank}
                        />
                        {errors.bankCode && touched.bankCode &&
                            <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK ACCOUNT
                    </div>
                    <MyInput id={"recipientsAccountNumber"}
                             name={"recipientsAccountNumber"}
                             touched={touched.recipientsAccountNumber}
                             value={values.recipientsAccountNumber}
                             onChange={handleChange}
                             isError={errors.recipientsAccountNumber}
                    />
                    {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                        <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            * Phone number
                        </div>
                        <PhoneCodesInput
                            id='phoneNumber'
                            name='phoneNumber'
                            onChange={handleChange}
                            value={values.phoneNumber}
                            error={errors.phoneNumber}
                            touched={touched.phoneNumber}
                            selectable={false}
                            onlyCountries={["za"]}
                        />
                        {errors.phoneNumber &&
                            <div className={s.error_message}>{errors.phoneNumber}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        TRANSFER REASON
                    </div>
                    <CustomDropdownForAzaTransactions
                        id={"transferReason"}
                        name={"transferReason"}
                        touched={touched.transferReason}
                        isError={errors?.transferReason?.name}
                        items={transferReasons}
                    />
                    {errors.transferReason && touched.transferReason &&
                        <div className={s.error_message}>{errors?.transferReason?.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        LEGAL ENTITY TYPE
                    </div>
                    <CustomDropdownForAzaTransactions
                        id={"legalEntityType"}
                        name={"legalEntityType"}
                        touched={touched.legalEntityType}
                        isError={errors?.legalEntityType?.name}
                        items={entityTypes}
                    />
                    {errors.legalEntityType && touched.legalEntityType &&
                        <div className={s.error_message}>{errors?.legalEntityType?.name}</div>}

                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        CONTACT FIRST NAME
                    </div>
                    <MyInput id={"firstName"}
                             name={"firstName"}
                             touched={touched.firstName}
                             value={values.firstName}
                             onChange={handleChange}
                             isError={errors.firstName}
                    />
                    {errors.firstName && touched.firstName &&
                        <div className={s.error_message}>{errors.firstName}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            CONTACT LAST NAME
                        </div>
                        <MyInput id={"lastName"}
                                 name={"lastName"}
                                 touched={touched.lastName}
                                 value={values.lastName}
                                 onChange={handleChange}
                                 isError={errors.lastName}
                        />
                        {errors.lastName && touched.lastName &&
                            <div className={s.error_message}>{errors.lastName}</div>}
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        REGISTRATION NUMBER
                    </div>
                    <MyInput id={"registrationNumber"}
                             name={"registrationNumber"}
                             touched={touched.registrationNumber}
                             value={values.registrationNumber}
                             onChange={handleChange}
                             isError={errors.registrationNumber}
                    />
                    {errors.registrationNumber && touched.registrationNumber &&
                        <div className={s.error_message}>{errors.registrationNumber}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_block}>
                        <div className={s.input_label}>
                            NATURE OF BUSINESS
                        </div>
                        <CustomDropdownForAzaTransactions
                            id={"natureOfBusiness"}
                            name={"natureOfBusiness"}
                            touched={touched.natureOfBusiness}
                            isError={errors?.natureOfBusiness?.name}
                            items={natureOfBusinessTypes}
                        />
                        {errors.natureOfBusiness && touched.natureOfBusiness &&
                            <div
                                className={s.error_message}>{errors?.natureOfBusiness?.name}</div>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ZarBank;
