import React, {useEffect, useState} from 'react';
import s from './ListOfTransfers.module.css'
import {ConfigProvider, DatePicker, Dropdown, Space} from "antd";
import arrow from "../../../../assets/images/personalAccount/arrow.svg";
import arrow_right from "../../../../assets/images/mobile/chevron.svg";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    ITransaction,
    setFilters,
    setTransactionTypeFilter
} from "../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {Formik} from "formik";
import dayjs from "dayjs";
import Table, {ColumnsType} from "antd/es/table";
import {ReactComponent as SortIconAsc} from "../../../../assets/images/personalAccount/sortArrow.svg";
import {ReactComponent as SortIconDesc} from "../../../../assets/images/personalAccount/sortArrowDown.svg";
import {ReactComponent as SortIcons} from "../../../../assets/images/personalAccount/sortIcons.svg";
import {toHumanDate} from "../../../../helpers/ConvertDate";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import CustomDropdownForAccounts from "../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {useSelector} from "react-redux";
import {selectTransactionsByFilter} from "../../../../store/reducers/TransactionsSlice/selector";
import {myDate} from "../../../../helpers/CalculateDate";
import {useAccountsForSelect} from "../../../../hooks/useAccountsForSelect";
import {getStatementThunk} from "../../../../store/reducers/ActionCreators";
import classnames from "classnames";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import {ReactComponent as Back} from '../../../../assets/images/personalAccount/back.svg'
import Icon from "@ant-design/icons";

// export interface DataType {
//     balance: number,
//     currency: string,
//     number: string,
//     status: string
// }

const columns: ColumnsType<any> = [
    {
        title: 'date',
        dataIndex: 'date',
        sorter: (a, b) => {
            return a.date.props["data-time"] - b.date.props["data-time"]
        },
        className: `${s.dateCell}`,
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        }
    },
    {
        title: 'transfer №',
        dataIndex: 'transferN',
    },
    {
        title: 'payer',
        dataIndex: 'payer',
    },
    {
        title: 'recipient',
        dataIndex: 'recipient',
    },
    {
        title: 'type',
        dataIndex: 'type',
    },
    {
        title: 'amount',
        dataIndex: 'amount',
        align: 'right',
        sorter: (a, b) => {
            return a.amount.props["data-amount"] - b.amount.props["data-amount"]
        },
        sortIcon: (props: { sortOrder: any }) => {
            if (props.sortOrder === 'ascend') {
                return <SortIconAsc/>
            }
            if (props.sortOrder === 'descend') {
                return <SortIconDesc/>
            }
            return <SortIcons/>
        },
        className: `${s.amountColumn}`
    },
    {
        title: 'status',
        dataIndex: 'status',
        align: 'right',
    },
];

const ListOfTransfers = () => {

    const sortedTransactions = useSelector(selectTransactionsByFilter)
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [selectedType, setSelectedType] = useState('ALL');
    const [isMobile, setIsMobile] = useState(false)
    const [statementLink, setStatementLink] = useState('');
    const [statementLinkIsDisabled, setStatementLinkIsDisabled] = useState(true)
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + (myDate.getDate() < 10 ? ("0" + myDate.getDate()).slice(-2) : myDate.getDate())),
        to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + (myDate.getDate() < 10 ? ("0" + myDate.getDate()).slice(-2) : myDate.getDate()))
    })
    const [AllDates, setAllDates] = useState([])
    const [unicDays, setUnicDays] = useState([])
    const [resultArray, setResultArray] = useState<any>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState<any>();
    const accountsList = useAccountsForSelect()
    const dispatch = useAppDispatch()

    useEffect(() => {
        return () => {
            dispatch(setFilters({
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: ''
            }))
        }
    }, [dispatch])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    useEffect(() => {

        const tempArr: any = []
        sortedTransactions?.forEach((transaction: ITransaction) => {

            let dateFormat = new Date(transaction.created * 1000);
            let day = dateFormat.getDate() > 9 ? dateFormat.getDate() : `0${dateFormat.getDate()}`
            let month = dateFormat.getMonth() + 1 > 9 ? dateFormat.getMonth() + 1 : `0${dateFormat.getMonth() + 1}`
            let humanDate = dateFormat.getFullYear() + "-" + month + "-" + day

            tempArr.push(humanDate)
        })
        setAllDates(tempArr)

    }, [sortedTransactions])

    useEffect(() => {

        const result = AllDates.reduce((acc: any, date: string) => {
            if (acc.includes(date)) {
                return acc;
            }
            return [...acc, date];
        }, []);

        setUnicDays(result)

    }, [AllDates])

    useEffect(() => {
        const resultArray = unicDays.map((MyDay) => {

            const arrayOfTransactionsByDay = sortedTransactions?.forEach((transaction: ITransaction) => {

                let startCurrentDateForTransactions = new Date(MyDay);
                startCurrentDateForTransactions.setHours(3, 0, 0, 0);

                let endCurrentDateForTransactions = new Date(MyDay);
                endCurrentDateForTransactions.setHours(26, 59, 59, 999);

                if ((startCurrentDateForTransactions.getTime() <= transaction.created * 1000) && (transaction.created * 1000 <= endCurrentDateForTransactions.getTime())) {
                    return transaction
                }
            })

            return (
                {
                    [MyDay]: arrayOfTransactionsByDay,
                }
            )
        })

        setResultArray(resultArray)
    }, [sortedTransactions, unicDays])

    useEffect(() => {
        dispatch(setTransactionTypeFilter(selectedType))
    }, [selectedType, dispatch])

    const handleTypePicker = ({label, key}: any) => {
        setSelectedType(key)
    }

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 55,
        width: isMobile ? "100%" : 146,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';

    const contentStyle = {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#979797'
    };

    const dropdownStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 17,
        width: isMobile ? '100%' : 316,
        height: 55,
        border: '1.5px solid #EEEFEF',
        borderColor: '#EEEFEF',
        borderRadius: 8,
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        background: '#FFFFFF'
    }

    const menuStyle = {
        border: '1.5px solid #EEEFEF',
        borderRadius: 8,
    };

    const transactionTypesArray = [
        {
            label: 'ACH Transaction',
            key: 'ACH Transaction',
        },
        {
            label: 'BACS Transaction',
            key: 'BACS Transaction',
        },
        {
            label: 'International Transaction (Pan Africa)',
            key: 'International Transaction (Pan Africa)',
        },
        {
            label: 'Europe Transaction (SEPA)',
            key: 'Europe Transaction (SEPA)',
        },
        {
            label: 'International Transaction(SWIFT)',
            key: 'International Transaction(SWIFT)',
        },
        {
            label: 'Intra Transaction',
            key: 'Intra Transaction',
        },
        {
            label: 'ALL',
            key: 'ALL',
        },
    ]

    const tableData: Array<any> = sortedTransactions.map((item: ITransaction) => {
        return {
            key: item.transactionNumber,
            date: <div data-time={item.created}><p className={s.dateCellP}>{toHumanDate(item.created).date}</p><p
                className={s.timeCellP}>{toHumanDate(item.created).time}</p></div>,
            transferN: item.transactionNumber,
            payer: item.fromAccount,
            recipient: item.recipient ? item.recipient : item.toAccount,
            type: item.transactionType,
            amount: <p data-amount={item.amount}
                className={item.direction === 'IN' ? `${s.in}` : `${s.out}`}>{item.direction === 'OUT' && '-'}{item.amount + ' ' + item.currency}</p>,
            status: <>
                <p>
                    {
                        item.transactionStatus === 'Denied' && <p className={s.rejected}>Rejected</p>
                    }
                    {
                        item.transactionStatus === 'New' && <p className={s.pending}>Pending</p>
                    }
                    {
                        item.transactionStatus === 'Approved' && <p className={s.confirmed}>Confirmed</p>
                    }
                </p>
            </>,
            description: 'test'
        }
    })

    const showModal = (transfer: ITransaction) => {
        setSelectedTransfer(transfer)
        setIsModalOpen(true)
    }

    const generateStatement = (tempData: any) => {
        dispatch(getStatementThunk(token, tempData))
            .then((res) => {
                setStatementLink(res.data)
                setStatementLinkIsDisabled(false)
                const url = process.env.REACT_APP_API_URL + res.data
                window.open(url, '_blank');
            })

    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        colorPrimary: 'var(--primary-color)',
                        fontWeightStrong: 600,
                        colorLink: 'red'
                    }
                },
                token: {
                    colorPrimary: 'var(--primary-color)',
                    colorBorder: '#EEEFEF',
                    fontFamily: 'Inter',
                },
            }}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen}>
                    <div>

                        <div className={s.row}>
                            <div className={s.transaction_info_row}>
                                <div className={s.transaction_time}>
                                    {   // @ts-ignore
                                        toHumanDate(selectedTransfer?.created).time
                                    }
                                </div>
                                <div className={s.transaction_type}>
                                    {selectedTransfer?.transactionType}
                                </div>
                            </div>
                        </div>

                        <div className={s.transfer_details_block}>
                            {
                                selectedTransfer?.transactionNumber !== '' &&
                                <div className={s.modal_row}>
                                    <span className={s.row_name}>Transfer №:</span>
                                    <span className={s.row_value}>
                                        {selectedTransfer?.transactionNumber}
                                    </span>
                                </div>
                            }

                            {
                                selectedTransfer?.fromAccount !== '' &&
                                <div className={s.modal_row}>
                                    <span className={s.row_name}>Payer:</span>
                                    <span className={s.row_value}>
                                        {selectedTransfer?.fromAccount}
                                    </span>
                                </div>
                            }

                            {
                                selectedTransfer?.recipient !== '' &&
                                <div className={s.modal_row}>
                                    <span className={s.row_name}>recipient:</span>
                                    <span className={s.row_value}>
                                        {selectedTransfer?.recipient} {selectedTransfer?.toAccount}
                                    </span>
                                </div>
                            }
                        </div>

                        {
                            selectedTransfer?.paymentRegion && <div className={s.international_details_block}>
                                {
                                    selectedTransfer?.recipientDetails?.recipientsBank !== '' &&
                                    <div>
                                        <div className={s.international_row_name}>Bank Name</div>
                                        <div className={s.international_row_value}>
                                            {selectedTransfer?.recipientDetails?.recipientsBank}
                                        </div>
                                    </div>
                                }

                                {
                                    selectedTransfer?.recipientDetails?.recipientsBank !== '' &&
                                    <div>
                                        <div className={s.international_row_name}>Bank Address</div>
                                        <div className={s.international_row_value}>
                                            {selectedTransfer?.recipientDetails?.recipientsBankAddress}
                                        </div>
                                    </div>
                                }

                                {
                                    selectedTransfer?.recipientDetails?.recipientsSwiftBic !== '' &&
                                    <div>
                                        <div className={s.international_row_name}>Swift/Bic Code</div>
                                        <div className={s.international_row_value}>
                                            {selectedTransfer?.recipientDetails?.recipientsSwiftBic}
                                        </div>
                                    </div>
                                }

                                {
                                    selectedTransfer?.recipientDetails?.recipientsAccountNumber !== '' &&
                                    <div>
                                        <div className={s.international_row_name}>Iban Code</div>
                                        <div className={s.international_row_value}>
                                            {selectedTransfer?.recipientDetails?.recipientsAccountNumber}
                                        </div>
                                    </div>
                                }

                                {
                                    selectedTransfer?.recipientDetails?.fullName !== '' &&
                                    <div>
                                        <div className={s.international_row_name}>Beneficiary Name</div>
                                        <div className={s.international_row_value}>
                                            {selectedTransfer?.recipientDetails?.fullName}
                                        </div>
                                    </div>
                                }

                                {
                                    selectedTransfer?.recipientDetails?.recipientsAddress !== '' &&
                                    <div>
                                        <div className={s.international_row_name}>Beneficiary Address</div>
                                        <div className={s.international_row_value}>
                                            {selectedTransfer?.recipientDetails?.recipientsAddress}
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        <div className={s.amount_block}>
                            <div>
                                {
                                    selectedTransfer?.transactionStatus === "New" &&
                                    <div
                                        className={classnames(s.status, s.pending)}>
                                        pending
                                    </div>
                                }

                                {
                                    selectedTransfer?.transactionStatus === "Approved" &&
                                    <div
                                        className={classnames(s.status, s.confirmed)}>
                                        confirmed
                                    </div>
                                }

                                {
                                    selectedTransfer?.transactionStatus === "Denied" &&
                                    <div
                                        className={classnames(s.status, s.rejected)}>
                                        rejected
                                    </div>
                                }
                            </div>
                            <div
                                className={classnames(s.amount, selectedTransfer?.transactionStatus === "Denied" && s.disable)}>
                                {selectedTransfer?.amount} {selectedTransfer?.currency}
                            </div>
                        </div>

                        <TransparentButton
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                            title={"Back"}
                            onClick={() => setIsModalOpen(false)}
                        />
                    </div>
                </CustomModal>

                <Formik
                    initialValues={{
                        accountNumber: "",
                        from: '',
                        to: '',
                    }}
                    onSubmit={(values) => {
                        const filtersData = {
                            ...values,
                            from: date.from,
                            to: date.to,
                            selectedType: selectedType
                        }
                        // const tempData = {
                        //     accountNumber: values.accountNumber,
                        //     dateFrom: date.from,
                        //     dateTo: date.to,
                        //     transferType: selectedType
                        // }
                        dispatch(setFilters(filtersData))

                        setStatementLinkIsDisabled(false)

                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.filters_block}>
                                <div className={s.account_dropdown_block}>
                                    <p className={s.labelText}>Payer account</p>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        id={'accountNumber'}
                                        name={'accountNumber'}
                                    />
                                </div>
                                <div className={s.date_picker_block}>
                                    <p className={s.labelText}>date from</p>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.from)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, from: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, from: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>

                                </div>
                                <div className={s.date_picker_block}>
                                    <p className={s.labelText}>date TO</p>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.to)}
                                                format={dateFormat}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, to: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, to: dateString})
                                                    }
                                                }}
                                    />
                                </div>
                                <div className={s.button_wrapper}>
                                    <TransparentButton
                                        large
                                        type={'submit'}
                                        title={"Show"} style={{height: "52px"}}/>
                                </div>
                            </div>

                            <div className={s.statement_wrapper}>
                                <div className={s.table_top}>
                                    <div className={s.period}>
                                        From {date.from} to {date.to}
                                    </div>
                                    <div className={s.flex}>
                                        <div>
                                            <p className={s.labelText}>transfer type</p>
                                            <Dropdown
                                                menu={{items: transactionTypesArray, onClick: handleTypePicker}}
                                                placement={"bottom"}
                                                trigger={['click']}
                                                dropdownRender={(menu: React.ReactNode) => (
                                                    <div style={contentStyle}>
                                                        {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                                    </div>
                                                )}>
                                                <Space style={dropdownStyle}>
                                                    <p className={s.account}>{selectedType}</p>
                                                    <img src={arrow} alt=""/>
                                                </Space>
                                            </Dropdown>
                                        </div>
                                        <div className={statementLinkIsDisabled ? s.disable : s.statement_link}
                                             onClick={() => generateStatement({
                                                 accountNumber: values.accountNumber,
                                                 dateFrom: date.from,
                                                 dateTo: date.to,
                                                 transferType: selectedType
                                             })}
                                        >
                                            <div className={s.generate_link}>
                                                Generate a Statement
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ConfigProvider theme={{
                                    components: {
                                        Pagination: {
                                            colorPrimary: '#FFFFFF',
                                            colorPrimaryHover: '#FFFFFF',
                                            colorBgContainer: 'var(--primary-color)',
                                            colorLink: '#FFFFFF'
                                        },
                                    },
                                }}>
                                    <Table
                                        bordered={false}
                                        style={{marginTop: 48}}
                                        rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` : ''}
                                        className={s.statementTable}
                                        columns={columns}
                                        pagination={{position: ['bottomCenter'], defaultPageSize: 15}}
                                        dataSource={tableData}

                                    />
                                </ConfigProvider>
                            </div>

                            <div className={s.statement_wrapper_mobile}>
                                <div className={s.header}>
                                    <div className={s.period}>
                                        From {date.from} to {date.to}
                                    </div>

                                    <div className={s.set_type_block}>
                                        <p className={s.labelText}>transfer type</p>
                                        <Dropdown
                                            menu={{items: transactionTypesArray, onClick: handleTypePicker}}
                                            placement={"bottom"}
                                            trigger={['click']}
                                            dropdownRender={(menu: React.ReactNode) => (
                                                <div style={contentStyle}>
                                                    {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                                </div>
                                            )}>
                                            <Space style={dropdownStyle}>
                                                <p className={s.account}>{selectedType}</p>
                                                <img src={arrow} alt=""/>
                                            </Space>
                                        </Dropdown>
                                    </div>

                                    <a className={statementLinkIsDisabled ? s.disable : s.statement_link}
                                       href={`https://api.iberbnc.pro:8766/${statementLink}`} target='_blank' rel="noreferrer">
                                        <div className={s.generate_link}>
                                            Generate a Statement
                                        </div>
                                    </a>
                                </div>

                                <div className={s.statement_mobile_body}>
                                    {
                                        resultArray?.map((datesObj: any, index: number) => {
                                            return (
                                                <div className={s.date_block_wrapper} key={index}>
                                                    <div className={s.transaction_date}>
                                                        {Object.keys(datesObj)[0]}
                                                    </div>

                                                    <div className={s.transactions_by_day_wrapper}>
                                                        {
                                                            Object.values(datesObj)?.map((transactionArray: any, index) =>
                                                                transactionArray?.forEach((transaction: any, index: number) => {
                                                                    if (transaction) {
                                                                        return (
                                                                            <div key={index} className={s.transaction}
                                                                                 onClick={() => showModal(transaction)}>
                                                                                <div className={s.row}>
                                                                                    <div className={s.transaction_info_row}>
                                                                                        <div className={s.transaction_time}>
                                                                                            {toHumanDate(transaction.created).time}
                                                                                        </div>
                                                                                        <div className={s.transaction_type}>
                                                                                            {transaction.transactionType}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={s.row}>
                                                                                    <div>
                                                                                        {
                                                                                            transaction.transactionStatus === "New" &&
                                                                                            <div
                                                                                                className={classnames(s.status, s.pending)}>
                                                                                                pending
                                                                                            </div>
                                                                                        }

                                                                                        {
                                                                                            transaction.transactionStatus === "Approved" &&
                                                                                            <div
                                                                                                className={classnames(s.status, s.confirmed)}>
                                                                                                confirmed
                                                                                            </div>
                                                                                        }

                                                                                        {
                                                                                            transaction.transactionStatus === "Denied" &&
                                                                                            <div
                                                                                                className={classnames(s.status, s.rejected)}>
                                                                                                rejected
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className={classnames(s.amount, transaction.transactionStatus === "Denied" && s.disable)}>
                                                                                        {transaction.amount} {transaction.currency}
                                                                                        <img src={arrow_right} alt=""/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        </form>)}
                </Formik>



            </div>
        </ConfigProvider>
    )
        ;
};

export default ListOfTransfers;
