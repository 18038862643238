import React, { useRef, useState } from "react";
import s from "./CardsWidget.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import { useNavigate } from "react-router-dom";
import CardsIcon from "../../../../assets/images/personalAccount/cards-white.svg";
import { useAppSelector } from "../../../../hooks/redux";

const CardsWidget = () => {
    const navigate = useNavigate();
    const cardWrapperRef = useRef<HTMLDivElement>(null);
    const cardsList = useAppSelector((store: any) => store.userReducer.cardInfo);
    const userCards = cardsList?.length >= 1 && cardsList.filter((card: any) => card.status !== -1 && card.status !== 9);

    const [currentIndex, setCurrentIndex] = useState(0);

    const scrollToIndex = (index: number) => {
        if (cardWrapperRef.current) {
            const cardWidth = 350;
            const offset = index * (cardWidth + 16);
            cardWrapperRef.current.scrollTo({
                left: offset,
                behavior: "smooth",
            });
            setCurrentIndex(index);
        }
    };

    return (
        <>
            <div className={s.cardsTitle}>Cards</div>
            {userCards.length > 0 ? (
                <>
                    <div className={userCards.length === 1 ? s.cardsWrapperSingle : s.cardsWrapper} ref={cardWrapperRef}>
                        {userCards.map((card: any, index: any) => (
                            <div key={index} className={userCards.length === 1 ? s.singleCard : s.card}>
                                <div className={s.cardFront}>
                                    <div className={s.chip}></div>
                                    <div className={s.cardNumber}>
                                        {card.cardNumber}
                                    </div>
                                    <div className={s.expireDate}>
                                        {card.expireDate}
                                    </div>
                                    <div className={s.cardHolder}>
                                        {card.cardHolder}
                                    </div>
                                    <div className={s.masterCard}></div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={s.navigationDots}>
                        {userCards.map((_: any, index: any) => (
                            <div
                                key={index}
                                className={`${s.dot} ${
                                    index === currentIndex ? s.active : ""
                                }`}
                                onClick={() => scrollToIndex(index)}
                            ></div>
                        ))}
                    </div>
                    <div className={s.buttonWrapper}>
                        <MyBtn
                            title={"Cards"}
                            large
                            icon={CardsIcon}
                            onClick={() => navigate("/personal_account/cards")}
                            type={"button"}
                        />
                    </div>
                </>
            ) : (
                <div className={s.noCardsWrapper}>
                    <div className={s.cardIcon}></div>
                    <div className={s.noCardsText}>No cards</div>
                    <div className={s.addButtonWrapper}>
                        <MyBtn
                            title={"+ Add Card"}
                            large
                            icon={CardsIcon}
                            onClick={() => navigate("/personal_account/cards")}
                            type={"button"}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default CardsWidget;
