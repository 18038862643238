import React, {useEffect, useState} from 'react';
import s from './NavBar.module.css'
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import classnames from "classnames";
import closePopup from "../../../assets/images/mobile/close_popup.svg"
import logo from "../../../assets/images/publicPages/logo.svg"
import arrow_dropdown from "../../../assets/images/publicPages/arrow_dropdown.svg";
import {
    ABOUT, AGENT_PROGRAM, CONTACTS, 
    // DEDICATED_IBANS,
    FAQ,
    FOREIGN_EXCHANGE,
    MONEY_ORDERS,
    MONEY_TRANSFERS,
    PAYMENT_SERVICE_PROVIDER,
    // WHITE_LABEL_SOLUTIONS,
    WHY_US
} from "../../../constants/RoutesConstants";
import Icon from "@ant-design/icons";
import {ReactComponent as CardCoin} from '../../../assets/images/publicPages/card-coin.svg'
import {ReactComponent as WalletMoney} from '../../../assets/images/publicPages/wallet-money.svg'
import {ReactComponent as Transfer} from '../../../assets/images/publicPages/transfer.svg'
import {ReactComponent as Medal} from '../../../assets/images/publicPages/medal-star.svg'
// import {ReactComponent as WhiteLabel} from '../../../assets/images/publicPages/whitelabel.svg'
// import {ReactComponent as Iban} from '../../../assets/images/publicPages/iban.svg'
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import SideMenu from "../../SideMenu/SideMenu";
import {useAppSelector} from "../../../hooks/redux";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}
const NavBar = ({isActive, setIsActive}: propsType) => {

    const [submenuIsOpen, setSubmenuIsOpen] = useState(false)
    const [IsPersonalSideMenu, setIsPersonalSideMenu] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = useAppSelector(state => state.userReducer.user.userToken)

    useEffect(() => {
        if (
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
        ) {
            setIsPersonalSideMenu(true)
        } else {
            setIsPersonalSideMenu(false)
        }
    }, [location.pathname])

    const handleNavigate = (path: any) => {
        setIsActive(false)
        navigate(path)
    }

    return (
        <>
            {
                IsPersonalSideMenu
                    ? <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <SideMenu isSide setIsActive={setIsActive}/>
                    </div>
                    : <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
                        <div className={s.close_img_block}>
                            <NavLink to={"/"} className={s.link} onClick={() => setIsActive(false)}>
                                <img src={logo} alt="" className={s.mobile_logo}/>
                            </NavLink>
                            <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
                        </div>
                        <div className={s.links_block}>
                            <NavLink to={"/"} className={s.link} onClick={() => setIsActive(false)}>
                                Home
                            </NavLink>
                            <NavLink to={ABOUT} className={s.link} onClick={() => setIsActive(false)}>
                                About Us
                            </NavLink>
                            <div className={s.link} onClick={() => setSubmenuIsOpen(prevState => !prevState)}>
                                Services <img className={classnames(submenuIsOpen ? s.arrow_reverse : s.arrow_initial)}
                                              src={arrow_dropdown} alt=""/>
                            </div>
                            <div
                                className={classnames(submenuIsOpen ? s.services_submenu_active : s.services_submenu_inactive)}>
                                {
                                    submenuIsOpen && <>
                                       {/* <Link to={VIRTUAL_CURRENCIES} className={s.dropdown_link}
                                              onClick={() => setIsActive(false)}>
                                            <Icon component={() => <Bitcoin/>} rev={undefined}/>
                                            <p className={s.link_text}>Embracing the World of Virtual Currencies</p>
                                        </Link>*/}

                                        <Link to={FOREIGN_EXCHANGE} className={s.dropdown_link}
                                              onClick={() => setIsActive(false)}>
                                            <Icon component={() => <CardCoin/>} rev={undefined}/>
                                            <p className={s.link_text}>Foreign Exchange Excellence</p>
                                        </Link>

                                        <Link to={MONEY_ORDERS} className={s.dropdown_link} onClick={() => setIsActive(false)}>
                                            <Icon component={() => <WalletMoney/>} rev={undefined}/>
                                            <p className={s.link_text}>Effortless Money Orders</p>
                                        </Link>

                                        <Link to={MONEY_TRANSFERS} className={s.dropdown_link}
                                              onClick={() => setIsActive(false)}>
                                            <Icon component={() => <Transfer/>} rev={undefined}/>
                                            <p className={s.link_text}>Seamless Money Transfers</p>
                                        </Link>

                                        <Link to={PAYMENT_SERVICE_PROVIDER} className={s.dropdown_link}
                                              onClick={() => setIsActive(false)}>
                                            <Icon component={() => <Medal/>} rev={undefined}/>
                                            <p className={s.link_text}>Your Trusted Payment Service Provider</p>
                                        </Link>

                                        {/* <Link to={WHITE_LABEL_SOLUTIONS} className={s.dropdown_link}
                                              onClick={() => setIsActive(false)}>
                                            <Icon component={() => <WhiteLabel/>} rev={undefined}/>
                                            <p className={s.link_text}>White Label Solutions</p>
                                        </Link> */}
{/* 
                                        <Link to={DEDICATED_IBANS} className={s.dropdown_link}
                                              onClick={() => setIsActive(false)}>
                                            <Icon component={() => <Iban/>} rev={undefined}/>
                                            <p className={s.link_text}>Dedicated IBANs</p>
                                        </Link> */}
                                    </>
                                }
                            </div>
                            <NavLink to={WHY_US} className={s.link} onClick={() => setIsActive(false)}>
                                Why Us?
                            </NavLink>
                            <NavLink to={FAQ} className={s.link} onClick={() => setIsActive(false)}>
                                FAQ
                            </NavLink>
                            <NavLink to={AGENT_PROGRAM} className={s.link} onClick={() => setIsActive(false)}>
                    Agent program
                </NavLink>
                <NavLink to={CONTACTS} className={s.link} onClick={() => setIsActive(false)}>
                                Contacts
                            </NavLink>
                        </div>
                        <div className={s.buttons_block}>
                            { isLogin
                                ? <TransparentButton title={"Back"} onClick={() => handleNavigate(-1)}/>
                                : <TransparentButton title={"Log In"} onClick={() => handleNavigate("/login")}/>
                            }
                        </div>
                    </div>
            }
        </>
    );
};

export default NavBar;
