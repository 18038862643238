import React, { useState, useEffect } from 'react';
import MyInput from "../../ui/MyInput/MyInput";
import s from "../../BankTransferFormBusiness/BankTransferFormBusiness.module.css";
import CustomDropdownForAzaTransactions from "../../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import { bankCodeListNigeriaUSDBank } from "../../../constants/StaticData";
import PhoneCodesInput from "../../ui/PhoneCodesInput/PhoneCodesInput";
import { getPhoneCode } from "../../BankTransferFormPersonal/FormHelpers/FormHelpers";

interface FormikProps {
    values: {
        name?: string;
        bankAccount?: string;
        phoneNumber?: string;
        bankCode?: {
            name?: string;
            value?: string;
        };
    };
    errors: {
        name?: string;
        bankAccount?: string;
        phoneNumber?: string;
        bankCode?: {
            name?: string;
            value?: string;
        };
    };
    touched: {
        name?: boolean;
        bankAccount?: boolean;
        phoneNumber?: boolean;
        bankCode?: {
            name?: boolean;
            value?: boolean;
        };
    };
    handleChange: (e: React.ChangeEvent<any>) => void;

    recipientsBankCountry: string;
    updateParentCode?: (value: any) => void; // Function that accepts a string
}

const NigeriaUsdBank: React.FC<FormikProps> = ({
    values,
    errors,
    touched,
    handleChange,
    recipientsBankCountry,
    updateParentCode
}) => {
    const [innerCode, setInnerCode] = useState<any>({
        "co": "ad",
        "ph": "376",
        "na": "Andorra"
    });

    useEffect(() => {
        const newCode = getPhoneCode(recipientsBankCountry, null);
        if (newCode !== innerCode) {
            setInnerCode(newCode);
            updateParentCode && updateParentCode(newCode);
        }
    }, [recipientsBankCountry, innerCode, updateParentCode]);

    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * COMPANY NAME
                    </div>
                    <MyInput
                        id={"name"}
                        name={"name"}
                        touched={touched.name}
                        value={values.name}
                        onChange={handleChange}
                        isError={errors.name}
                    />
                    {errors.name && touched.name &&
                        <div className={s.error_message}>{errors.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * PHONE NUMBER
                    </div>
                    <PhoneCodesInput
                        id='phoneNumber'
                        name='phoneNumber'
                        onChange={handleChange}
                        value={values.phoneNumber}
                        error={errors.phoneNumber}
                        touched={touched.phoneNumber}
                        selectable={false}
                        onlyCountries={["ng"]}
                    />
                    {errors.phoneNumber &&
                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK CODE
                    </div>
                    <CustomDropdownForAzaTransactions
                        id={"bankCode"}
                        name={"bankCode"}
                        touched={touched.bankCode}
                        isError={errors?.bankCode?.name}
                        items={bankCodeListNigeriaUSDBank}
                    />
                    {errors.bankCode && touched.bankCode &&
                        <div className={s.error_message}>{errors?.bankCode?.name}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * BANK ACCOUNT
                    </div>
                    <MyInput
                        id={"bankAccount"}
                        name={"bankAccount"}
                        touched={touched.bankAccount}
                        value={values.bankAccount}
                        onChange={handleChange}
                        isError={errors.bankAccount}
                    />
                    {errors.bankAccount && touched.bankAccount &&
                        <div className={s.error_message}>{errors.bankAccount}</div>}
                </div>
            </div>
        </div>
    );
};

export default NigeriaUsdBank;
