import React, {useState} from 'react';
import s from './PersonalAccountHeader.module.css'
import burger from '../../assets/images/mobile/burger.svg'
import NavBar from "../Header/NavBar/NavBar";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";
import CustomModalContactContainer from "../ui/CustomModalContactContainer/CustomModalContactContainer";

const PersonalAccountHeader = () => {

    const [navIsShow, setNavIsShow] = useState(false)
    const navigate = useNavigate()
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
            <CustomModalContactContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <div className={s.wrapper}>
                <div className={s.left}>
                    <div className={s.user_round} onClick={() => navigate('/personal_account/dashboard')}>
                        { firstName?.slice(0,1)}{lastName?.slice(0,1)}{companyName?.slice(0,1)}
                    </div>
                    <div className={s.round} onClick={() => setIsModalOpen(true)}>
                        <div className={s.mail}></div>
                    </div>
                </div>

                <div className={s.right}>
                    <div>
                        <img src={burger} alt="" onClick={() => setNavIsShow(true)}/>
                    </div>
                </div>
                <NavBar isActive={navIsShow} setIsActive={setNavIsShow}/>
            </div>
        </>
    );
};

export default PersonalAccountHeader;
