import React, {useEffect, useState} from 'react'
import s from './AccountOverview.module.css'

import plus from "../../../../assets/images/personalAccount/plusWhite.svg"
import {Checkbox, ConfigProvider, Dropdown, Layout, MenuProps, Popover, Space} from 'antd';
import arrow from '../../../../assets/images/personalAccount/arrow.svg'
import Table, {ColumnsType} from "antd/es/table";
import info from "../../../../assets/images/personalAccount/information.svg";

import {ReactComponent as Back} from '../../../../assets/images/personalAccount/back.svg'
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import Icon from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {CreateAccountsThunk, GetAccountsListThunk} from '../../../../store/reducers/ActionCreators';
import {Loader} from '../../../../components/Loader/Loader';
import CustomModal from '../../../../components/ui/CustomModal/CustomModal';
import MyBtn from '../../../../components/ui/MyBtn/MyBtn';
import TransparentButton from '../../../../components/ui/TransparentButton/TransparentButton';
import AccountCardMobile from "../../../../components/ui/AccountCardMobile/AccountCardMobile";

const menuStyle = {
    border: '1.5px solid #EEEFEF',
    borderRadius: 8,
};

const contentStyle = {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#979797'
};

const currencyStyle = {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#979797',
    width: 'fit-content'
};

interface DataType {
    pendingIncomingTransactionsSum: number;
    pendingOutgoingTransactionsSum: number;
    balance: number,
    currency: string,
    number: string,
    status: string
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Number',
        dataIndex: 'number',
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        align: 'right'
    },
    {
        title: 'Pending incoming transactions',
        dataIndex: 'pendingIncomingTransactions',
        align: 'right'
    },
    {
        title: 'Pending Outgoing transactions',
        dataIndex: 'pendingOutgoingTransactions',
        align: 'right'
    },
    {
        title: 'Current Balace',
        dataIndex: 'totalBalance',
        align: 'right'
    },
    {
        title: 'Available balance',
        dataIndex: 'availableBalance',
        align: 'right'
    },
];

export const AccountOverview = () => {
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const accountsStatistics = useAppSelector(store => store.userReducer.accountsList.statistics)
    const accounts = useAppSelector(store => store.userReducer.accountsList.accounts)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const [showModal, setShowModal] = useState(false)
    const [currency, setCurrency] = useState('USD')
    const [newAccounts, setNewAccounts] = useState({
        CAD: false,
        EUR: false,
        GBP: false,
        USD: false,
        USDT: false,
        SGD: false,
        DDT: false,
        HKD: false,
        JPY: false,
        CNH: false,
        AED: false,
        NGN: false,
        CNY: false,
    })
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        dispatch(GetAccountsListThunk(userToken, currency))
    }, [dispatch, userToken])


    const optionsTwo: MenuProps['items'] = [
        {
            label: 'Commission statement for the previous year',
            key: '0',
        },
        {
            label: 'Commission statement from the beginning of the current calendar year',
            key: '1',
        },
    ];

    const options: MenuProps['items'] = [
        {
            label: 'CAD',
            key: 'CAD',
        },
        {
            label: 'EUR',
            key: 'EUR',
        },
        {
            label: 'GBP',
            key: 'GBP',
        },
        {
            label: 'USD',
            key: 'USD',
        },
        {
            label: 'USDT',
            key: 'USDT',
        },
        {
            label: 'SGD',
            key: 'SGD',
        },
        {
            label: 'HKD',
            key: 'HKD',
        },
        {
            label: 'JPY',
            key: 'JPY',
        },
        {
            label: 'CNH',
            key: 'CNH',
        },
        {
            label: 'AED',
            key: 'AED',
        },
        {
            label: 'NGN',
            key: 'NGN',
        },
        {
            label: 'CNY',
            key: 'CNY',
        },
    ];

    const menuItems = options.map((option: any) => ({
        key: option.value,
        label: (
            <div onClick={() => currencyHandleChange(option.key)}>
                {option.label}
            </div>
        ),
    }));

    const getType = (type: string) => {
        if (type === 'Requested') {
            return 'pending approval'
        }
        if (type === 'Inactive') {
            return 'blocked'
        }
    }

    const tableData = accounts?.map((item: DataType) => {
        return {
            key: item.number,
            number: <div className={item.status === 'Active' ? s.numberWrapper : `${s.numberWrapper} ${s.disabled}`}>
                {item.number}
                <Popover content={"Your internal account number."}
                         color={'#EEEFEF'}
                         placement={"bottom"}

                         overlayInnerStyle={{
                             fontFamily: 'Inter',
                             padding: '13px 16px',
                             backgroundColor: '#EEEFEF',
                             boxShadow: 'none'
                         }}
                >
                    <img src={info} alt=""/>
                </Popover>
            </div>,
            currency: <p className={item.status !== 'Active' ? s.disabled : ''}>{item.currency}</p>,
            pendingIncomingTransactions: <p className={item.status !== 'Active' ? s.disabled : ''}>{item.pendingIncomingTransactionsSum}</p>,
            pendingOutgoingTransactions: <p className={item.status !== 'Active' ? s.disabled : ''}>{item.pendingOutgoingTransactionsSum}</p>,
            totalBalance: item.status !== 'Requested' ?
                <p className={item.status !== 'Active' ? s.disabled : ''}>{item.balance + ' ' + item.currency}</p> : '',
            availableBalance: item.status === 'Active' ? item.balance + ' ' + item.currency :
                <p className={item.status === 'Requested' ? s.pending : s.blocked}>{getType(item.status)}</p>,
        }
    })

    const onChange = (e: CheckboxChangeEvent) => {
        e.stopPropagation()

        let name = e.target.name;
        if (name === 'CAD') {
            setNewAccounts({...newAccounts, CAD: !newAccounts.CAD})
        }
        if (name === 'EUR') {
            setNewAccounts({...newAccounts, EUR: !newAccounts.EUR})
        }
        if (name === 'GBP') {
            setNewAccounts({...newAccounts, GBP: !newAccounts.GBP})
        }
        if (name === 'USD') {
            setNewAccounts({...newAccounts, USD: !newAccounts.USD})
        }
        if (name === 'USDT') {
            setNewAccounts({...newAccounts, USDT: !newAccounts.USDT})
        }
        if (name === 'SGD') {
            setNewAccounts({...newAccounts, SGD: !newAccounts.SGD})
        }
        if (name === 'HKD') {
            setNewAccounts({...newAccounts, HKD: !newAccounts.HKD})
        }
        if (name === 'JPY') {
            setNewAccounts({...newAccounts, JPY: !newAccounts.JPY})
        }
        if (name === 'CNH') {
            setNewAccounts({...newAccounts, CNH: !newAccounts.CNH})
        }
        if (name === 'AED') {
            setNewAccounts({...newAccounts, AED: !newAccounts.AED})
        }
        if (name === 'NGN') {
            setNewAccounts({...newAccounts, NGN: !newAccounts.NGN})
        }
        if (name === 'CNY') {
            setNewAccounts({...newAccounts, CNY: !newAccounts.CNY})
        }

    };

    const createAccounts = () => {
        const currencies = []
        for (let newAccountsKey in newAccounts) {
            // @ts-ignore
            if (newAccounts[newAccountsKey] === true) {
                currencies.push(newAccountsKey)
            }
        }
        !!currencies.length &&
        dispatch(CreateAccountsThunk(userToken, {currencies}))
            .then((res) => {
                if (res.status === 200) {
                    setShowSuccess(true)
                }
            }).catch((e) => {
            setShowError(true)
        })
    }

    const getSuccessTemplate = () => {
        return (
            <>
                <h3 className={s.modalTitle}>Success!</h3>
                <h4 className={s.modalSubtitleSuccess}>Your request has been sent.</h4>
                <div className={s.buttonsWrapperSuccess}>
                    <MyBtn style={{width: 180}} title={'Ok'} onClick={() => {
                        setShowSuccess(false);
                        setShowModal(false);
                        setNewAccounts({
                            CAD: false,
                            EUR: false,
                            GBP: false,
                            USD: false,
                            USDT: false,
                            SGD: false,
                            DDT: false,
                            HKD: false,
                            JPY: false,
                            CNH: false,
                            AED: false,
                            NGN: false,
                            CNY: false,
                        })
                        dispatch(GetAccountsListThunk(userToken, currency))
                    }}/>
                </div>
            </>
        )
    }

    const getErrorTemplate = () => {
        return (
            <>
                <h3 className={s.modalTitle}>Oops!</h3>
                <h4 className={s.modalSubtitleSuccess}>Something went wrong. Please try again</h4>
                <div className={s.buttonsWrapperSuccess}>
                    <MyBtn buttonType={'error'} style={{width: 180}} title={'Ok'} onClick={() => {
                        setShowSuccess(false);
                        setShowError(false);
                        setNewAccounts({
                            CAD: false,
                            EUR: false,
                            GBP: false,
                            USD: false,
                            USDT: false,
                            SGD: false,
                            DDT: false,
                            HKD: false,
                            JPY: false,
                            CNH: false,
                            AED: false,
                            NGN: false,
                            CNY: false,
                        })
                    }}/>
                </div>
            </>
        )
    }

    const getTemplateForCreateNewAccount = () => {
        return (
            <>
                <h3 className={s.modalTitle}>Add a new account</h3>
                <h4 className={s.modalSubtitle}>Choose a currency for the new account (one or more).</h4>
                <div className={s.checkboxes}>
                    <ConfigProvider theme={{
                        token: {
                            fontSize: 14,
                            fontFamily: 'Inter',
                            colorText: '#979797',
                            colorPrimary: 'var(--primary-color)',
                            colorBorder: '#EEEFEF',
                            colorPrimaryBorderHover: '#979797'
                        }
                    }}>
                        <Checkbox name={'CAD'} checked={newAccounts.CAD} onChange={onChange}>CAD</Checkbox>
                        <Checkbox name={'EUR'} checked={newAccounts.EUR} onChange={onChange}>EUR</Checkbox>
                        <Checkbox name={'GBP'} checked={newAccounts.GBP} onChange={onChange}>GBP</Checkbox>
                        <Checkbox name={'USD'} checked={newAccounts.USD} onChange={onChange}>USD</Checkbox>
                        <Checkbox name={'USDT'} checked={newAccounts.USDT} onChange={onChange}>USDT</Checkbox>
                        <Checkbox name={'SGD'} checked={newAccounts.SGD} onChange={onChange}>SGD</Checkbox>
                        <Checkbox name={'HKD'} checked={newAccounts.HKD} onChange={onChange}>HKD</Checkbox>
                        <Checkbox name={'JPY'} checked={newAccounts.JPY} onChange={onChange}>JPY</Checkbox>
                        <Checkbox name={'CNH'} checked={newAccounts.CNH} onChange={onChange}>CNH</Checkbox>
                        <Checkbox name={'AED'} checked={newAccounts.AED} onChange={onChange}>AED</Checkbox>
                        <Checkbox name={'NGN'} checked={newAccounts.NGN} onChange={onChange}>NGN</Checkbox>
                        <Checkbox name={'CNY'} checked={newAccounts.CNY} onChange={onChange}>CNY</Checkbox>
                    </ConfigProvider>
                </div>
                <div className={s.buttonsWrapper}>
                    <TransparentButton onClick={() => {
                        setShowModal(false)
                    }} style={{width: 180}} title={'Cancel'} medium
                                       icon={<Icon component={() => <Back/>} rev={undefined}/>}/>
                    <MyBtn style={{width: 180}} title={'Send Request'} medium onClick={() => createAccounts()}/>
                </div>
            </>
        )
    }

    const currencyHandleChange = (e: any) => {
        setCurrency(e)
        dispatch(GetAccountsListThunk(userToken, e))
    }

    const isMobile = window.innerWidth <= 768

    return (
        isLoading ? <Loader/> :
            <div className={s.accountOverviewWrapper}>

                <CustomModal type={showError ? 'error' : undefined} setIsModalOpen={setShowModal}
                             isModalOpen={showModal} children={
                    <div className={s.modalContent}>
                        {
                            (showError && getErrorTemplate()) || (showSuccess ? getSuccessTemplate() : getTemplateForCreateNewAccount())
                        }
                    </div>
                }/>
                <div className={s.topMenu}>
                    <h2 className={s.topMenuTitle}>Currency Accounts</h2>
                    <div className={s.topMenuButtons}>
                        <div className={s.mobileRow}>
                            <div className={s.statement}>Generate a Statement</div>
                            <Dropdown menu={{items: optionsTwo}} trigger={['click']}
                                      dropdownRender={(menu: React.ReactNode) => (
                                          <div style={contentStyle}>
                                              {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                          </div>
                                      )}>
                                <Space style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 139,
                                    height: 44,
                                    border: '1.5px solid #EEEFEF',
                                    borderColor: '#EEEFEF',
                                    borderRadius: 12,
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    lineHeight: '18px',
                                    color: '#979797'
                                }}>
                                    <p>More details</p>
                                    <img alt={'dropdownArrow'} className={s.arrow_img} src={arrow}/>
                                </Space>
                            </Dropdown>
                        </div>
                        <MyBtn icon={plus}
                               style={{paddingLeft: '26px', paddingRight: '22px', width: 'fit-content', height: 44}}
                               onClick={() => setShowModal(true)}
                               title={'New Account'}/>

                    </div>
                </div>
                {!isMobile
                    ? <Layout>
                        <Table
                            bordered={false}
                            className={s.accountTable}
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            summary={(pageData) => {
                                let total = 0;
                                pageData.forEach(() => {
                                    total += total;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={3} className={s.footerTotalCell}>
                                                <p>Total:</p>
                                                <Dropdown menu={{items: menuItems}} placement={"bottom"}
                                                          trigger={['click']}
                                                          dropdownRender={(menu: React.ReactNode) => (
                                                              <div style={currencyStyle}>
                                                                  {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                                              </div>
                                                          )}>
                                                    <Space style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: 86,
                                                        height: 44,
                                                        border: '1.5px solid #EEEFEF',
                                                        borderColor: '#EEEFEF',
                                                        borderRadius: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        lineHeight: '18px',
                                                        cursor: 'pointer'
                                                    }}>
                                                        <p>{currency}</p>
                                                        <img src={arrow} alt=""/>
                                                    </Space>
                                                </Dropdown>

                                            </Table.Summary.Cell>

                                            <Table.Summary.Cell index={2} align={'right'} colSpan={2}
                                                                className={s.footerCell}>
                                                {accountsStatistics?.totalPendingIncomingTransactionsSum} {currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align={'right'} className={s.footerCell}>
                                                {accountsStatistics?.totalPendingOutgoingTransactionsSum} {currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align={'right'} className={s.footerCell}>
                                                {accountsStatistics?.totalBalance} {currency}
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align={'right'} className={s.footerCell}>
                                                {accountsStatistics?.totalAvailableBalance} {currency}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Layout>
                    : <>
                        {
                            accounts.map((item: any) =>
                                <AccountCardMobile
                                    status={item.status}
                                    number={item.number}
                                    currency={item.currency}
                                    balance={item.balance}
                                />
                            )
                        }
                    </>
                }
            </div>

    )
}
