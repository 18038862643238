import React, {Dispatch, SetStateAction, useState} from 'react';
import {useField, useFormikContext} from "formik";
import s from "./CustomDropdownForAzaTransactions.module.css";
import selectArrow from "../../../assets/images/common/select_arrow.svg";
import classnames from "classnames";

type PropsType = {
    items: any
    isError?: string | undefined
    setCountryError?: any
    placeholder?: string
    id: string
    name?: any
    touched?: any
    customId?: string
    setCurrency?: Dispatch<SetStateAction<string>>
}

const CustomDropdownForAzaTransactions = (props: PropsType) => {

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { setFieldValue } = useFormikContext();
  const [field] = useField<string>(props.name);

  if (isDropdownVisible) {
    const myPopup: any = document.querySelector(`#${props.id}`);
    document.addEventListener("click", (e) => {
      const withinBoundaries = e.composedPath().includes(myPopup);

      if (!isDropdownVisible) return;

      if (!withinBoundaries) {
        setIsDropdownVisible(false);
      }
    });
  }

  return (
    <div className={s.phoneInputWrapper} id={props.id}>
      <div
        className={classnames(s.mySelect, {
            [s.error]: props.isError && props.touched,
        })}
        onClick={() => {
          setIsDropdownVisible((prevState) => !prevState);
        }}
      >
        <div className={s.selectedBlock}>
          <img
            className={isDropdownVisible ? s.arrowReverse : s.arrow}
            width={9}
            height={5}
            src={selectArrow}
            alt=""
          />
        </div>
        <div className={isDropdownVisible ? s.optionsWrapper : s.none}>
          {props.items.map((item: any, index: number) => (
            <div
              className={s.option}
              key={index}
              onClick={() => {
                if (props.name != null) {
                  setFieldValue(props.name, item);
                  if (props.setCurrency) {
                    props.setCurrency(item.currency);
                  }
                }
              }}
            >
              <div className={s.countryName}>
                {item.name} : {item.value}
              </div>
            </div>
          ))}
        </div>
      </div>
      <input
        className={classnames(s.my_input, {
          [s.error]: props.isError && props.touched,
      })}
        id={props.id}
        name={props.name}
        type="text"
        // @ts-ignore
        value={field.value.name}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default CustomDropdownForAzaTransactions;
