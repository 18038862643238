import React, { Dispatch, SetStateAction, useState } from 'react';
import s from "./CardsStepOne.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import VirtualCardRequest from "../../../../components/Card/VirtualCardRequest/VirtualCardRequest";
import { useAppSelector } from "../../../../hooks/redux";
import { cardCountries, phonecodes } from "../../../../constants/StaticData";

type PropsType = {
    current: number;
    setCurrent: Dispatch<SetStateAction<number>>;
    secondCard?: boolean;
    setHideContent?: Dispatch<SetStateAction<boolean>>;
    hideContent?: boolean;
};

const CardsStepOne= (props: PropsType) => {
    const [showForm, setShowForm] = useState(false);
    const userData = useAppSelector(store => store.userReducer.user.userData?.country) || undefined;

    const getCountryByCode = (code: string | undefined) => {
        if (!code) return undefined;
        const country = phonecodes.find(entry => entry.co.toLowerCase() === code.toLowerCase());
        return country?.na;
    };

    const userCountryName = getCountryByCode(userData);
    const isCountryInCardCountries = typeof userCountryName === 'string' && cardCountries.includes(userCountryName);

    const handleCancelClick = () => {
        setShowForm(false);
        props.setHideContent && props.setHideContent(false);
    }
    const handleSuccessClick = () => props.setCurrent(1);

    return (
        <>
            {(!props.secondCard) ? (
                (!showForm) ? (
                    <div>
                        <div className={s.countryText}>
                            Countries of Issuance available for cardholders below: <br />
                            {cardCountries.join(', ')}.
                        </div>
                        <div className={s.createCardWrapper}>
                            <div className={s.cardIcon}></div>
                            <p className={s.cardsText}>NO CARDS</p>
                            <div className={s.buttonWrapper}>
                                <MyBtn
                                    title={'+ Add Card'}
                                    large
                                    onClick={() => setShowForm(true)}
                                    localDisable={!isCountryInCardCountries}
                                    type={"submit"}
                                />
                            </div>
                        </div>
                    </div>
                ): ( 
                    <VirtualCardRequest
                        onCancelClick={handleCancelClick}
                        onSuccessClick={handleSuccessClick}
                    />
                )
            ): (
                (!props.hideContent) ? (
                    <>
                         <div className={s.hr} />
                        <div className={s.buttonWrapper}>
                            <MyBtn
                                title={'+ Add Card'}
                                large
                                onClick={() => {
                                    props.setHideContent && props.setHideContent(true);
                                    setShowForm(true);
                                }}
                                localDisable={!isCountryInCardCountries}
                                type={"submit"}
                            />
                        </div>
                    </>
                ):(
                    <VirtualCardRequest
                        onCancelClick={handleCancelClick}
                        onSuccessClick={handleSuccessClick}
                    />
                )

            )}
        </>
    );
};

export default CardsStepOne;
