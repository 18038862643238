import React from 'react';
import s from "../BankTransferFormPersonal/BankTransferFormPersonal.module.css";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import {Popover} from "antd";
import info from "../../assets/images/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import {languages} from "../../constants/StaticData";

interface FormikProps {
    values: any;
    errors: any;
    touched: any;
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const EftForm: React.FC<FormikProps> = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                        }) => {
    return (
        <div className={s.block}>
            <div className={s.title}>
                Recipient’s Details
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * first name
                    </div>
                    <MyInput id={"firstName"}
                             name={"firstName"}
                             touched={touched.firstName}
                             value={values.firstName}
                             onChange={handleChange}
                             isError={errors.firstName}
                    />
                    {errors.firstName && touched.firstName &&
                        <div className={s.error_message}>{errors.firstName}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * last name
                    </div>
                    <MyInput id={"lastName"}
                             name={"lastName"}
                             touched={touched.lastName}
                             value={values.lastName}
                             onChange={handleChange}
                             isError={errors.lastName}
                    />
                    {errors.lastName && touched.lastName &&
                        <div className={s.error_message}>{errors.lastName}</div>}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * institution number
                    </div>
                    <MyInput id={"institutionNumber"}
                             name={"institutionNumber"}
                             touched={touched.institutionNumber}
                             value={values.institutionNumber}
                             onChange={handleChange}
                             isError={errors.institutionNumber}
                    />
                    {errors.institutionNumber && touched.institutionNumber &&
                        <div className={s.error_message}>{errors.institutionNumber}</div>}
                </div>

                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * transit number
                    </div>
                    <MyInput id={"transitNumber"}
                             name={"transitNumber"}
                             touched={touched.transitNumber}
                             value={values.transitNumber}
                             onChange={handleChange}
                             isError={errors.transitNumber}
                    />
                    {errors.transitNumber && touched.transitNumber &&
                        <div className={s.error_message}>{errors.transitNumber}</div>}
                </div>
            </div>

            <div className={s.row}>
                <div className={s.input_block}>
                    <div className={s.input_label}>
                        * account number
                    </div>
                    <MyInput id={"recipientsAccountNumber"}
                             name={"recipientsAccountNumber"}
                             touched={touched.recipientsAccountNumber}
                             value={values.recipientsAccountNumber}
                             onChange={handleChange}
                             isError={errors.recipientsAccountNumber}
                    />
                    {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                        <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                </div>

                <div className={s.mobile_row}>
                    <div className={classnames(s.input_block, s.short)}>
                        <div className={s.input_label}>
                            * Email
                            <Popover
                                content={"Please indicate the recipient’s email address and language, " +
                                    "if you would like us to send them an electronically signed automatic email " +
                                    "about the performed transfer."}
                                color={'#EEEFEF'}
                                overlayInnerStyle={{
                                    fontFamily: 'Inter',
                                    width: '280px',
                                    padding: '13px 16px'
                                }}
                            >
                                <img src={info} alt=""/>
                            </Popover>
                        </div>
                        <MyInput id={"beneficiaryEmail"}
                                 name={"beneficiaryEmail"}
                                 touched={touched.beneficiaryEmail}
                                 value={values.beneficiaryEmail}
                                 onChange={handleChange}
                                 isError={errors.beneficiaryEmail}
                        />
                        {errors.beneficiaryEmail && touched.beneficiaryEmail &&
                            <div className={s.error_message}>{errors.beneficiaryEmail}</div>}
                    </div>

                    <div className={s.languages_dropdown}>
                        <CustomDropdown
                            items={languages}
                            id="emailLanguage"
                            name="emailLanguage"
                            isError={errors.emailLanguage}
                            touched={touched.emailLanguage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EftForm;
